
import { IBreakingFields, IBreakingDetial, IBreakingSearch, IBreakingDetailFields, IBreakingDetailSearch, IBreakingDetailDetial } from "./breakingType"
import { IMealPage } from "@/types/AppTypes";
import { httpRequest } from "@/utils/HttpRequest";
class BreakingService {
    async page(searchVo: IBreakingSearch) {
        const apiUrl = "/booking/statistics/user";
        return await httpRequest.post<IMealPage<IBreakingDetial>>(apiUrl, searchVo);
    }
    async export(searchVo: IBreakingFields) {
        const apiUrl = "/booking/statistics/user/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo);
    }
    async detailpage(searchVo: IBreakingDetailSearch) {
        const apiUrl = "/booking/statistics/userDetail";
        return await httpRequest.post<IMealPage<IBreakingDetailDetial>>(apiUrl, searchVo);
    }
    async detailexport(searchVo: IBreakingDetailFields) {
        const apiUrl = "/booking/statistics/userDetail/export";
        return await httpRequest.postFile<boolean>(apiUrl, searchVo);
    }
}
export const breakingService = new BreakingService();
