import React, { useEffect, useState } from "react";
import { Modal, message } from "antd";
import QRCode from 'qrcode.react';
import useNavModel from "@/model/navModel";
import loginBg from "../../assets/login.png";
import { ToolOutlined } from "@ant-design/icons";
import "./home.less";
import useGlobalModel from "@/model/globalModel";
import { IRestaurantDetial } from "../restaurant/restaurantTypes";
import { mealService } from "@/views/meal/mealService";
import { IMealFields } from "../meal/mealTypes";
import { releaseService } from "../food/release/releaseService";
import { useHomeModel, IWSSMealInfo, IWSSInfo, IWSSEnumType } from "./homeModal";
import moment from "moment";
import {getBaseWebscocketUrl, getToken} from "@/utils/constants";
import { IMealTypeObj, IMealTypeEnum } from "@/types/AppEnums";
import { IBookingDetail } from "./homeService";
import { useLockFn } from "ahooks";


let ws: WebSocket;

declare const window: Window & { wsBool: boolean };

export function Home() {
    const { addNav, selectedNavId } = useNavModel();
    const { dataRestaurantSource, user: { token }, setRestaurantSourceOne } = useGlobalModel();
    const { homeInfo, setHomeInfo, foods, bookingStatic, setFoods, getBookingDetail, setBookingStatic } = useHomeModel();
    const [info, setInfo] = useState<any>({})
    // const [wsLinkNum, setWsLinkNum] = useState<number>(0)
    // const [wsBool, setWsBool] = useState<boolean>(true)
    useEffect(() => {
        // console.log("改变wsBool状态",selectedNavId)
        // setWsBool(true)
        window.wsBool = true
    }, [])
    useEffect(() => {
        let setCanteenModal = Modal.confirm({
            title: <span style={{ fontSize: "20px", fontWeight: "bold" }}>请选择餐厅</span>,
            centered: true,
            closable: false,
            icon: <ToolOutlined />,
            className: "login-shop-modal",
            content: <div className="login-shop-container">
                {
                    dataRestaurantSource.length > 0 ? dataRestaurantSource.map(item => (
                        <span key={item.id} onClick={() => setConteen(item, setCanteenModal)}>{item.name}</span>
                    )) : <span onClick={() => { addNav("2"); setCanteenModal.destroy(); }} style={{ background: "#FD8700" }}>去增加餐别</span>
                }
            </div>,
        });
        return () => {
            setHomeInfo(null);
            setCanteenModal.destroy();
        }
    }, [dataRestaurantSource]);

    //选中当前需要链接的餐厅,请求餐别
    const setConteen = useLockFn(async (item: IRestaurantDetial, canteenModalInst: any) => {
        setRestaurantSourceOne(item)
        const canteenId = item.id as string;
        let { retData: { data: mealData } } = await mealService.page({
            canteenId,
            "page": {
                "pageSize": 10,
                "pageNum": 1
            }
        });
        canteenModalInst?.destroy();
        let setMealModal = Modal.confirm({
            title: <span style={{ fontSize: "20px", fontWeight: "bold" }}>请选择餐别</span>,
            centered: true,
            closable: false,
            icon: <ToolOutlined />,
            className: "login-shop-modal",
            content: <div className="login-shop-container">
                {
                    mealData && mealData.length > 0 ? mealData.map(item => (
                        <span key={item.id} onClick={() => setFoodMenu(item, canteenId, setMealModal)}>{IMealTypeObj[item.mealType as IMealTypeEnum]}</span>
                    )) : <span onClick={() => { addNav("3"); setMealModal.destroy(); }} style={{ background: "#FD8700" }}>去增加餐别</span>
                }
            </div>,
            // onCancel: () => { loginServiceAuth2.Logout(); setLoading(false); }
        });
    })
    let wsLinkNum: number = 0

    //选中餐别 去根据当前时间请求到 当前的菜单
    const setFoodMenu = useLockFn(async (item: IMealFields, canteenId: string, mealModalInst: any) => {
        wsLinkNum = 0
        let { retData } = await releaseService.findByMeal({
            canteenId,
            "mealId": item.id,
            "startTime": moment().format("YYYY-MM-DD"),
            "endTime": moment().format("YYYY-MM-DD")
        });
        // console.log(retData)
        // debugger;
        if (retData && retData.length > 0) {
            // console.log(canteenId, retData[0].id)
            setHomeInfo({
                canteenId: canteenId,
                menuId: retData[0].id as string,
            });
            setInfo({
                canteenId: canteenId,
                menuId: retData[0].id as string,
            })
            createWebSocket(canteenId, retData[0].id as string)
            getBookingDetail(retData[0].id as string)
        }
        mealModalInst?.destroy();
    })

    const speechTest = () => {
        let speech = new SpeechSynthesisUtterance();
        // 设置朗读内容和属性
        speech.text = "您已就餐";//播放文本
        speech.volume = 1;//播放音量
        speech.rate = 2; //播放语速
        speech.pitch = 1;//音调高低
        speechSynthesis.speak(speech);
    }


    //一，创建ws
    const createWebSocket = (canteenId: string = homeInfo?.canteenId ?? "", menuId: string = homeInfo?.menuId ?? "") => {

        let baseWebscocketUrl = getBaseWebscocketUrl();

        //let wsUrl = `ws://81.70.105.136:5011/report-meal-socket?token=${getToken()}&canteen=${canteenId}&menu?=${menuId}`;//测试
        // let wsUrl = `wss://api-2.yunhaogroup.com/report-meal-socket?token=${getToken()}&canteen=${canteenId}&menu=${menuId}`;//生产1
        //let wsUrl = `ws://report-meal-websocket.yunhaogroup.com/report-meal-socket?token=${getToken()}&canteen=${canteenId}&menu?=${menuId}`;//生产
        let wsUrl = `${baseWebscocketUrl}?token=${getToken()}&canteen=${canteenId}&menu?=${menuId}` //测试
        try {
            ws = new WebSocket(wsUrl);
            init(wsUrl, canteenId, menuId);
        } catch (e) {
            console.log('长链接网络异常');
        }
    }
    // let wsBool: Boolean = true
    useEffect(() => {
        return () => {
            console.log("连接关闭")
            window.wsBool = false
            ws?.close()
        }
    }, [])

    const [bookingInfo, setBookingInfo] = useState<IWSSMealInfo | null>(null);
    //二，调用init方法，该方法内把一些监听事件封装如下：当网络断开的时候，会先调用onerror，onclose事件可以监听到，会调用reconnect方法进行重连操作。正常的情况下，是先调用onopen方法的，当接收到数据时，会被onmessage事件监听到。
    const init = (wsUrl: string, canteenId: string, menuId: string) => {
        ws.onclose = function () {
            console.log('链接关闭', window.wsBool);
            window.wsBool && reconnect(canteenId, menuId);
        };
        ws.onerror = function () {
            console.log('发生异常了', window.wsBool);
            window.wsBool && reconnect(canteenId, menuId);
        };
        ws.onopen = function () {
            //心跳检测重置
            heartCheck.start();
        };
        ws.onmessage = (info: MessageEvent) => {
            if (info.data === "pong") {
                heartCheck.start();
                return false
            }
            let bookingInfo: IWSSInfo = JSON.parse(info.data);
            if (bookingInfo.event === IWSSEnumType.CONNECT_RET) {
                let retData = bookingInfo.retData as string;
                if (retData === "success") {
                    message.success("餐厅营业成功");
                } else {
                    message.warning(retData ?? "");
                }
            } else if (bookingInfo.event === IWSSEnumType.MEAL) {
                let retData = bookingInfo.retData as IWSSMealInfo;
                setBookingInfo(retData);
                speechTest()

            } else if (bookingInfo.event === IWSSEnumType.TOTAL) {
                let { bookingTotal, unfinishedTotal, cancelTotal, menuDetail: { foods } } = bookingInfo.retData as IBookingDetail;
                setFoods(foods)
                setBookingStatic({ bookingTotal, unfinishedTotal, cancelTotal, })
            }
            //拿到任何消息都说明当前连接是正常的
            console.log('接收到消息', info, window.wsBool);
            heartCheck.start();
        }
    }
    let lockReconnect: boolean = false;//避免重复连接
    let tt: NodeJS.Timeout;
    //三， 重连操作 reconnect：如果网络断开的话，会执行reconnect方法，使用了一个定时器，4秒后会重新创建一个新的websocket链接，重新调用createWebSocket函数，重新会执行及发送数据给服务器端。
    const reconnect = (canteenId: string, menuId: string) => {
        console.log(homeInfo, info, wsLinkNum)
        if (lockReconnect) {
            return;
        };
        lockReconnect = true;
        //没连接上会一直重连，设置延迟避免请求过多
        if (wsLinkNum <= 5) {
            tt && clearTimeout(tt);
            tt = setTimeout(function () {
                // canteenId, menuId
                createWebSocket(canteenId, menuId);
                lockReconnect = false;
                wsLinkNum += 1
                // setWsLinkNum(wsLinkNum + 1)
            }, 4000);
        } else {
            message.error("重连次数过多，请重新进入页面");
        }

    }

    interface IWebSocketHeart {
        timeout: number,
        timeoutObj: NodeJS.Timeout | null,
        serverTimeoutObj: NodeJS.Timeout | null,
        start: () => void
    }
    //四，心跳检测
    const heartCheck: IWebSocketHeart = {
        timeout: 10000,
        timeoutObj: null,
        serverTimeoutObj: null,
        start() {
            // console.log('我的心跳呢？？');
            let self = this;
            this.timeoutObj && clearTimeout(this.timeoutObj);
            this.serverTimeoutObj && clearTimeout(this.serverTimeoutObj);
            this.timeoutObj = setTimeout(() => {
                //这里发送一个心跳，后端收到后，返回一个心跳消息，
                let msg: string = "ping"
                // console.log('发送一个心跳');
                ws.send(msg);

                self.serverTimeoutObj = setTimeout(() => {
                    ws.close();
                    // createWebSocket();
                }, self.timeout);

            }, this.timeout)
        }
    };
    const onColse = () => {
        ws.close()
        window.wsBool = true

        // setWsLinkNum(0)
    }
    return (
        <div className="home-box">
            <div className="home">
                <div className="home-left">
                    <div className="left-login" onClick={() => addNav("2")}>
                        <img src={loginBg} alt="云豪集团" />
                        <span>&nbsp;用餐系统</span>
                    </div>
                    <div className="left-amount">
                        <div className="amount-item">
                            <span className="amount-item-num">{bookingInfo ? bookingInfo?.bookingTotal : bookingStatic.bookingTotal}</span>
                            <span className="amount-item-size">今日预约</span>
                        </div>
                        <div className="amount-item">
                            <span className="amount-item-num">{bookingInfo ? bookingInfo?.unfinishedTotal : bookingStatic.unfinishedTotal}</span>
                            <span className="amount-item-size">待就餐</span>
                        </div>
                        <div className="amount-item">
                            <span className="amount-item-num">{bookingInfo ? bookingInfo?.cancelTotal : bookingStatic.cancelTotal}</span>
                            <span className="amount-item-size">取消就餐</span>
                        </div>
                    </div>
                    <div className="left-qr">
                        <p>
                            {homeInfo ? <QRCode
                                value={`&canteenId=${homeInfo.canteenId}&menuId=${homeInfo.menuId}`}
                                size={340} /> : <span>没有发布菜单</span>
                            }
                        </p>
                        <span>请扫码用餐</span>
                    </div>
                </div>
                <div className="home-right">
                    <div className="person">
                        {/* <p className="person-item">
                            <span onClick={() => onColse()} className="left">断开连接WS</span>
                        </p> */}
                        <p className="person-item">
                            <span onClick={() => addNav("2")} className="left">用餐人：</span>
                            <span className="right">{bookingInfo?.user?.name ?? '用餐人'}</span>
                        </p>
                        <p className="person-item">
                            <span className="left">部&nbsp;&nbsp;&nbsp;&nbsp;门：</span>
                            <span className="right">{bookingInfo?.user?.orgName ?? '部门'}</span>
                        </p>
                    </div>
                    <div className="menu">
                        {bookingInfo && bookingInfo?.foods.map(item => (<span className="menu-item" key={item.id}>{item.name}</span>))}
                        {!!!bookingInfo && foods.map(item => (
                            <span className="menu-item" key={item.id}>{item.name}
                                <span className="menu-num">{item.bookingNum}</span>
                            </span>))}
                    </div>
                </div>
            </div>
        </div>

    )
}
