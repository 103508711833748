import { createModel } from "hox";
import { useLockFn } from "ahooks";
import { globalPrompt } from "@/components/message";
import { useState } from "react";
import { IFoods } from "../food/release/releaseTypes";
import { homeService, IMenuFoods, IIBookingStatic, IBookingDetail } from "./homeService";

export interface IHomeInfo {
    "canteenId": string,
    "menuId": string,
}
export enum MealStatus {
    FINISHED = 'finished',     // 已完成
    UNFINISHED = 'unfinished', // 未完成
    OVERDUE = 'overdue',       // 已失约
    EVALUATED = 'evaluated'    // 已评价
}

export interface IWSSMealInfo {
    "orgCode": string,
    "orgName": string,
    "mealStatus": MealStatus,
    "mealTime": string,
    "status": number,
    "note": string,
    "orderNum": number,
    "foods": IFoods[],
    "event": string,
    bookingTotal: string, //总预约数
    unfinishedTotal: string, // 未就餐人数
    cancelTotal: string, //取消人数
    name: string,
    user: {
        name: string,
        orgName: string
    }
}

export enum IWSSEnumType {
    CONNECT_RET = 'CONNECT_RET',  //链接信息
    MEAL = 'MEAL',    //就餐 餐别菜单
    TOTAL = 'TOTAL'   //预约 菜单数量
}

export interface IWSSInfo {
    event: IWSSEnumType,
    retData: string | IWSSMealInfo | IBookingDetail
}


export const useHomeModel = createModel(function () {
    const [homeInfo, setHomeInfo] = useState<IHomeInfo | null>(null)
    const [foods, setFoods] = useState<IMenuFoods[]>([])
    const initBookStatic = {
        bookingTotal: 0,
        unfinishedTotal: 0,
        cancelTotal: 0,
    }
    const [bookingStatic, setBookingStatic] = useState<IIBookingStatic>(initBookStatic)

    const getBookingDetail = async (id: string) => {
        let { retData } = await homeService.bookingDetail(id)
        let { bookingTotal, unfinishedTotal, cancelTotal, menuDetail: { foods } } = retData
        setFoods(foods)
        setBookingStatic({ bookingTotal, unfinishedTotal, cancelTotal, })
    }
    return {
        homeInfo, setHomeInfo,
        foods, setFoods,
        bookingStatic, setBookingStatic,
        getBookingDetail,

    }
})