import { httpRequest } from "../../utils/HttpRequest";
import { IMealPage } from "@/types/AppTypes";
import { IRestaurantSearch, IRestaurantDetial } from "./restaurantTypes"
class RestaurantService {
    async page(searchVo: IRestaurantSearch) {
        const apiUrl = "/common/canteen/page";
        return await httpRequest.post<IMealPage<IRestaurantDetial>>(apiUrl, searchVo);
    }
    async insert(searchVo: IRestaurantDetial) {
        const apiUrl = "/common/canteen/insert";
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    async update(searchVo: IRestaurantDetial) {
        const apiUrl = "/common/canteen/update";
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    async remove(code: string) {
        const apiUrl = `/common/canteen/remove/${code}`;
        return await httpRequest.get<boolean>(apiUrl);
    }
}
export const restaurantService = new RestaurantService();
