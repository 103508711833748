import React, { FC, useEffect, useImperativeHandle } from "react";
import { Form, Row } from "antd";
import { IYhForm } from "./Types";
import { __FormItem as FormItem } from "./item";


export const __YhForm: FC<IYhForm> = ({ formRef, items, ...props }) => {
    const [form] = Form.useForm();
    const { labelCol, labelAlign, disabled, formValues, onChange, onFinish } = props
    const onValuesChange = (changedValues: any, values: any) => {
        //disabled ? form.setFieldsValue(formValues) : onChange?.(changedValues);
        onChange?.(changedValues);
    }
    useEffect(() => {
        // form.resetFields();
        form.setFieldsValue(formValues);
    }, [formValues]);

    useImperativeHandle(formRef, () => ({
        validateFields: async () => {
            return await form.validateFields();
        },
        setFieldsValue: form.setFieldsValue,
        getFieldsValue: () => {
            return form.getFieldsValue()
        },
        resetFields: form.resetFields,
        inst: () => {
            return form;
        }
    }));
    return <Form
        form={form}
        onValuesChange={onValuesChange}
        onFinish={onFinish}
        labelCol={labelCol}
        labelAlign={labelAlign}
    >
        <Row
            justify="start"
        >
            {
                items.map((el, index) => {
                    return <FormItem key={index} {...el} disable={el.disable ?? disabled} />
                })
            }
        </Row>
    </Form>;
}