import { httpRequest } from "@/utils/HttpRequest";
import { ILibraryFields, ILibrarySearch, ILibraryDetial } from "./libraryTypes"
import { IMealPage } from "@/types/AppTypes";
class LibraryService {
    async page(searchVo: ILibrarySearch) {
        const apiUrl = "/common/food/page";
        return await httpRequest.post<IMealPage<ILibraryDetial>>(apiUrl, searchVo);
    }
    async insert(searchVo: ILibraryDetial) {
        const apiUrl = "/common/food/insert";
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    async update(searchVo: ILibraryDetial) {
        const apiUrl = "/common/food/update";
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    async remove(code: string) {
        const apiUrl = `/common/food/remove/${code}`;
        return await httpRequest.get<boolean>(apiUrl);
    }
    async import(code: string) {
        const apiUrl = `/common/food/import`;
        return await httpRequest.get<boolean>(apiUrl);
    }
    async export(searchVo: ILibraryDetial) {
        const apiUrl = `/common/food/export`;
        return await httpRequest.getFile(apiUrl);
    }
}
export const libraryService = new LibraryService();
