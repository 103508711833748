import React from "react";
import { useLibraryModel } from "../libraryModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import useGlobalModel from "@/model/globalModel";
import { useDictModel } from "@/views/dict/dictModel";
import { DictCodeEnmu } from "@/types/AppDictEnums";
import { IFoodCategoryOotions } from "@/types/AppEnums";

export function PageHeader() {
    const { getSelectedDictOne } = useDictModel()

    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useLibraryModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            span: 4,
            placeholder: "菜品名称",
            fieldName: "name",
            size: "small",
        },
        {
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "菜品分类",
            fieldName: "category",
            options: IFoodCategoryOotions,
            size: "small",
        },
    ]
    return (
        <YhPageHeader
            title="菜品管理"
            triggerSearch={true}
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    );
}