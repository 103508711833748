import { createModel } from 'hox';
import { useState, useEffect } from 'react';
import {
	IMealReportSearch,
	IMealReportDetial,
	IMealReportFields,
	IMealReportStatistics,
	IMealReportList,
} from './mealReportType';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants';
import { mealReportService } from './mealReportService';
import { ISearchPage } from '@/types/AppTypes';
import { transPgToPagination } from '@/model/globalModel';
import { useLockFn } from 'ahooks';
import { globalPrompt } from '@/components/message';
import { convertRes2Blob } from '@/utils/exportFile';

export const useMealReportModel = createModel(function () {
	//列表数据源
	const [dataSource, setDataSource] = useState<IMealReportDetial[]>([]);
	//查询条件
	const [searchParam, setSearchParam] = useState<IMealReportFields>({});
	//抽屉框
	const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
	const initDesc = {
		orgTotal: 0, // 33,
		bookingTotal: 0, // 3361,
		finishedTotal: 0, // 2697,
		canceledTotal: 0, // 200,
		overdueTotal: 0, // 464,
		overdueRate: 0, // "13.81",
	};
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	const [descTotal, setDescTotal] = useState<IMealReportStatistics>(initDesc);

	//表格多选id值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	const loadData = async (page?: ISearchPage, searchValue: IMealReportFields = searchParam) => {
		const searchParams = {
			...searchValue,
			page: {
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await mealReportService.page(searchParams);
		let {
			data,
			orgTotal,
			bookingTotal,
			finishedTotal,
			canceledTotal,
			overdueTotal,
			overdueRate,
			...pg
		} = retData;
		setPgCfg(transPgToPagination(pg));
		setDataSource(data);
		setDescTotal({
			orgTotal,
			bookingTotal,
			finishedTotal,
			canceledTotal,
			overdueTotal,
			overdueRate,
		});
	};
	//设置搜索value
	const updateSearchParam = (updateParam: IMealReportFields) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};
	//重置搜索参数
	const resetSearchParam = () => {
		setSearchParam({});
		setSelectedRowKeys([]);
		loadData(initPgCfg, {});
	};
	// 列表导出
	const listExport = async () => {
		let result = await mealReportService.export(searchParam);
		convertRes2Blob(result);
	};
	return {
		dataSource,
		searchParam,
		initPgCfg,
		pgCfg,
		selectedRowKeys,
		visibleDrawer,
		setDataSource,
		setSearchParam,
		setPgCfg,
		loadData,
		updateSearchParam,
		resetSearchParam,
		setSelectedRowKeys,
		setVisibleDrawer,
		listExport,
		descTotal,
		setDescTotal,
	};
});
