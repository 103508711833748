import React, { FC } from "react";
import { IFormItem, ISelectOption } from "../Types";
import { Form } from "antd";
import YhDebounceSelect from "@/components/YhDounceSelect";

export const __FormAsyncSelect: FC<IFormItem> = ({ placeholder, fieldLabel, fieldName, selectMode, initWidth, defaultQuery, ...props }) => {

	function fn(): Promise<ISelectOption[]> {
		let arr: ISelectOption[] = [];
		return Promise.resolve(arr)
	}

	return (
		<Form.Item
			label={fieldLabel}
			name={fieldName}
			rules={props.rules}
			labelCol={props.labelCol}
		>
			<YhDebounceSelect
				showSearch
				disabled={props.disable}
				placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
				onChange={(value: string, option) => props.onSelectChange?.(value, option as ISelectOption)}
				style={{ width: '100%' }}
				fetchOptions={props?.selectfetchList ?? fn}
				defaultQuery={defaultQuery}
			/>
		</Form.Item>
	)
}