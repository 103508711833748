import { IMdCmFields } from "../types/AppTypes";
import { MdEnableStatusEnum } from "../types/AppEnums";

//系统标识  测试和生产主数据标识区别
export const CLIENT_APP_CODE = "scm";

export const DEFAULT_PAGE_SIZE = 15;
export const DEFAULT_FIRST_PAGE_NUM = 1;
export const DEFAULT_REQUEST_TIMEOUT = 30000;

//API请求环境
const baseRequestUrl = {
	// "DEV": "http://api-2.laoniuche.cn/report-meal",
	"DEV": "http://localhost:5010/report-meal",
	"STAGE": "http://api-2.laoniuche.com/report-meal",
	"TEST": "http://api-2.laoniuche.cn/report-meal",
	"PROD": "https://api-2.yunhaogroup.com/report-meal"
}

//API请求环境
const baseRequestUrl1 = {
	"DEV": "http://api-2.laoniuche.cn",
	"STAGE": "http://api-2.laoniuche.com",
	"TEST": "http://api-2.laoniuche.cn",
	"PROD": "https://api-2.yunhaogroup.com"

}


//鉴权2的请求环境
const baseAuth2RequestUrl = {
	"DEV": "http://api-2.laoniuche.cn",
	"STAGE": "http://api-2.laoniuche.com",
	"TEST": "http://api-2.laoniuche.cn",
	"PROD": "https://api-2.yunhaogroup.com"
}

const  baseWebscocketUrl={
	"DEV": "ws://test-report-meal-websocket.laoniuche.cn/report-meal-socket",
	"STAGE": "ws://test-report-meal-websocket.laoniuche.cn/report-meal-socket",
	"TEST": "ws://test-report-meal-websocket.laoniuche.cn/report-meal-socket",
	"PROD": "ws://report-meal-websocket.yunhaogroup.com/report-meal-socket"


}


//根据环境变量自动获取图片查看地址
export const getBaseImgLookUrl = (md5: string) => {
	const REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD" | "STAGE";
    //自动选择环境
    return `${baseRequestUrl1[REACT_APP_ENV ?? "DEV"]}/fsrv/fetch/${md5}?Authorization=${getToken()}`
}

//根据环境变量自动获取图片上传地址
export const getBaseImgUploadUrl = () => {
	const REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD" | "STAGE";
    //自动选择环境
    return `${baseRequestUrl1[REACT_APP_ENV ?? "DEV"]}/fsrv/upload_file?Authorization=${getToken()}`
}

//根据环境变量自动获取图片上传地址
export const getBaseUploadData = () => {
    return {
        appCode: CLIENT_APP_CODE,
        fileMode: 0,
    }
}
// const baseFileRequestUrl = "http://file-server.laoniuche.cn/file";
// /**
//  * 上传文件路径
//  */
// export const uploadFileUrl = `${baseFileRequestUrl}/uploadFile`;
// /**
//  * 获取文件服务器文件
//  * @param id
//  * @returns
//  */
// export const loadFileUrl = (id: string) => `${baseFileRequestUrl}/fetch?id=${id}`;
//根据环境变量自动获取请求地址
export const getBaseRequestUrl = () => {
	//自动选择环境
	//....
	const REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD" | "STAGE";
	return baseRequestUrl[REACT_APP_ENV ?? "DEV"]
};

//根据环境变量自动获取鉴权请求地址
export const getBaseAuth2RequestUrl = () => {
	const REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD" | "STAGE";
	return baseAuth2RequestUrl[REACT_APP_ENV ?? "DEV"]
};

export const  getBaseWebscocketUrl = () => {

	let  REACT_APP_ENV = process.env.REACT_APP_ENV as "DEV" | "TEST" | "PROD" | "STAGE";
	return baseWebscocketUrl[REACT_APP_ENV ?? "DEV"]

}


//服务器自定义错误码
export const INTERNAL_SERVER_ERROR_CODE = 500;
//token存储KEY值
export const TOKEN_KEY = "token";

//获取token
export const getToken = () => {
	return sessionStorage.getItem(TOKEN_KEY) ?? "default-token-str"
};

//存储token
export const setToken = (token: string) => {
	sessionStorage.setItem(TOKEN_KEY, token)
};
//通用字段定义
export const defaultMdCmParam: IMdCmFields = {
	sourceApp: "",
	sourceAuthKey: "",
	branch: "",
	ver: undefined,
	enable: MdEnableStatusEnum.ENABLE,
};

export const branchDs = [
	{ label: "财务分支", value: "fms" },
	{ label: "主分支", value: "main" },
	{ label: "供应链分支", value: "scm" },
]
