import React, { useEffect } from "react";
import { Table, Switch, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useRestaurantModel } from "../restaurantModel";
import { IRestaurantDetial } from "../restaurantTypes";
import moment, { Moment } from "moment";
import useGlobalModel from "@/model/globalModel";
import { useDictModel } from "@/views/dict/dictModel";
import { getBaseImgLookUrl } from "@/utils/constants";
import { Image } from 'antd';
import { IFoodCategoryOotions, IMealTypeObj } from "@/types/AppEnums";
export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys,
        setIVisibleDrawer,
        setSelectedRow,
        removeDetail,
        setMd5FormVal
    } = useRestaurantModel();

    const { getTreeNodeOne } = useDictModel();
    const onEdit = (record: IRestaurantDetial) => {
        setSelectedRow(record)
        setIVisibleDrawer("edit")
        setMd5FormVal(record.image ? [record.image] : [])
        setSelectedRowKeys([record.id as string])
    }
    const onRemove = (id: string) => {
        removeDetail(id)
    }
    const columns: ColumnsType<IRestaurantDetial> = [
        {
            title: '序号',
            width: 80,
            render: (text, record, index) => `${index + 1}`,
            fixed: "left"
        }, {
            title: '餐厅名称',
            width: 130,
            dataIndex: 'name',
        }, {
            title: '餐厅地址',
            width: 130,
            dataIndex: 'address',
        }, {
            title: '餐别',
            width: 130,
            dataIndex: 'meals',
            // render:(text)=> text.map(())
            render: (text: any) => {
                let mealsList: string[] = []
                text.forEach((elem: any) => {
                    mealsList.push(IMealTypeObj[elem.mealType])
                })
                return Array.from(new Set(mealsList)).toString();
            }
        }, {
            title: '菜类',
            width: 130,
            dataIndex: 'categoryList',
            render: (text) => IFoodCategoryOotions.filter(item => text.indexOf(item.value)!== -1).map(itemMap => itemMap.label).toString()
            // }, {
            //     title: '营业时间',
            //     width: 130,
            //     dataIndex: 'phone',
        }, {
            title: '餐厅头像',
            width: 130,
            dataIndex: 'image',
            render: (text: string, record: IRestaurantDetial) => <Image width={60} src={getBaseImgLookUrl(text as string)} alt={record.name} />
            // render:(text)=>
        }, {
            title: '餐厅介绍',
            width: 130,
            dataIndex: 'note',
        }, {
            title: '操作',
            width: 130,
            dataIndex: 'phone',
            render: (text, record) => <div>
                <span className="click-span" onClick={() => onEdit(record)}>编辑</span>
                <span className="click-span" onClick={() => onRemove(record.id as string)}>删除</span>
            </div>
        }
    ];


    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id: string) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === id) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([id as string]);
        }
    };

    return (
        <Table
            rowKey={record => record.id as string}
            // rowSelection={{
            //     type: "radio",
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: IRestaurantDetial[]) => {
            //         setSelectedRowKeys(selectedRowKeys as string[]);
            //     }
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string)
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
