export enum DictCodeEnmu {
    //客户类型
    CUSTOMER_TYPE = "customer-type",
    // 菜品标签
    CAIPINBIAOQIAN = "caipingbiaoqian",
    // 菜品分类
    CAIPINFENLEI = "caipinfeilei",
    //所属机构
    SUOSHUJIGOU= "suoshujigou"
}
export const sourceAppOrBranch = {
    sourceApp: "vrm",
    branch: "vrm"
}