import React, { useRef } from "react";
import { YHDrawer } from "@/components/YhDrawer";
import { useLibraryModel } from "../libraryModel";
import { YhForm } from "@/components/YhFormBox";
import { IYhForm, IYhFormRef, FormItemTypeEnum } from "@/components/YhFormBox/Types";
import { ILibraryDetial } from "../libraryTypes";
import { message } from "antd";
import { getFormValidatorErrText } from "@/utils/utils";
import { useDictModel } from "@/views/dict/dictModel";
import { DictCodeEnmu } from "@/types/AppDictEnums";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { IFoodCategoryOotions } from "@/types/AppEnums";
export function DetailDrawer() {
    const { dataRestaurantSource } = useGlobalModel()
    const {
        visibleDrawer: { visible, mode },
        selectedRow,
        setVisibleDrawer,
        setIVisibleDrawer,
        insertDetail,
        editDetail,
        md5FormVal, setMd5FormVal
    } = useLibraryModel()
    const { getSelectedDictOne } = useDictModel()
    const formRef = useRef<IYhFormRef>();
    const onCancel = () => {
        setIVisibleDrawer()
        setMd5FormVal([])
    }
    const onSubmit = () => {
        formRef.current?.validateFields().then((val: any) => {
            // console.log(val)
            let newVal = {
                ...val,
                // category: val.category.toString(),
                // label: val.label.toString(),
                // canteen: val.canteen.toString()
            }
            mode === "edit" ? editDetail(newVal) : insertDetail(newVal)
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }
    const onSetFieldsValue = (formValues: { [key: string]: any }, isFormItem: boolean = true) => {
        // console.log(selectedRow)
        setMd5FormVal(formValues.image)
    }
    const onChange = (formValues: { [key: string]: any }) => {
        console.log("这里被调用了",formValues)
    }
    const formConfig: IYhForm<ILibraryDetial> = {
        formRef,
        labelCol: { span: 6 },
        onChange,
        formValues: mode === "edit" ? { ...selectedRow, canteens: selectedRow?.canteens?.map(item => item.id), labelCode: selectedRow?.labelCode?.split(',') } : {},
        items: [
            {
                type: FormItemTypeEnum.Input,
                span: 24,
                fieldLabel: "菜品名称",
                fieldName: "name",
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "菜品分类",
                fieldName: "category",
                span: 24,
                // selectMode: "multiple",
                // options: getSelectedDictOne(DictCodeEnmu.CAIPINFENLEI)
                options: IFoodCategoryOotions
                // options: [
                //     { label: "香", value: "1" },
                //     { label: "好香", value: "2" },
                //     { label: "真香", value: "3" },

                // ],
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "菜品标签",
                fieldName: "labelCode",
                span: 24,
                selectMode: "multiple",
                options: getSelectedDictOne(DictCodeEnmu.CAIPINBIAOQIAN)
                // options: [
                //     { label: "爆炒", value: "1" },
                //     { label: "红烧", value: "2" },
                //     { label: "蒜蓉", value: "3" },

                // ],
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "餐厅名称",
                fieldName: "canteens",
                span: 24,
                selectMode: "multiple",
                options: transOptions(dataRestaurantSource),
            },
            {
                type: FormItemTypeEnum.FileImage,
                fieldLabel: "上传图片",
                fieldName: "image",
                isOne: true,
                span: 24,
                md5List: md5FormVal,
                onSetFieldsValue
            },
        ]
    }
    return (
        <YHDrawer
            {...{
                title: `菜品管理${mode === "add" ? '新增' : '编辑'}`,
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}