import { httpRequest } from "@/utils/HttpRequest";
import { IPage } from "@/types/AppTypes";
import { Key } from "react";
export interface IMEALDictDetail {
    key:string
    id: string,// "",
    code: string,// "",
    name: string,// "",
    value: string,// "",
    pcode: string,// "",
    note: string,// "",
    order_num: string,// "",
    status: string,// "",
    children: IMEALDictDetail[],// []
}
export interface IMEALDictInsert {
    code?: string,// "",
    name?: string,// "",
    value?: string,// "",
    pcode?: string,// "",
    note?: string,// "",
    order_num?: string,// "",
    status?: number,// "",
}
export interface IMEALDictUpdate {
    id?: string,
    code?: string,// "",
    name?: string,// "",
    value?: string,// "",
    pcode?: string,// "",
    note?: string,// "",
    order_num?: string,// "",
    status?: number,// "",
}
export interface IMEALDictSearch extends IMEALDictInsert {
    page?: {
        pageSize: number;
        pageNum: number;
    }
}

class DictService {
    //树列表
    async tree(searchVo: IMEALDictSearch) {
        const apiUrl = "/common/data_dict/tree";
        return await httpRequest.post<IMEALDictDetail[]>(apiUrl, searchVo)
    }
    //树列表
    async page(searchVo: IMEALDictSearch) {
        const apiUrl = "/dict/page";
        return await httpRequest.post<IMEALDictDetail[]>(apiUrl, searchVo)
    }
    //新增
    async insert(searchVo: IMEALDictInsert) {
        const apiUrl = "/common/data_dict/insert";
        return await httpRequest.post<any>(apiUrl, searchVo)
    }
    //修改
    async update(searchVo: IMEALDictUpdate) {
        const apiUrl = "/common/data_dict/update";
        return await httpRequest.post<any>(apiUrl, searchVo)
    }
    //删除
    async remove(code: string) {
        const apiUrl = `/common/data_dict/remove/${code}`;
        return await httpRequest.get<any>(apiUrl)
    }
}
export const dictService = new DictService();
