import React, {useEffect} from "react";
import {Table} from "antd";
import {ColumnsType} from "antd/es/table";
import {useBreakingModel} from "../breakingModel";
import {IBreakingDetial} from "../breakingType";

export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys,
        visibleModel, setVisibleModel
    } = useBreakingModel();
    const onLook = (record: IBreakingDetial) => {
        setVisibleModel(true)
        setSelectedRowKeys([record.userId as number])
    }
    const columns: ColumnsType<IBreakingDetial> = [
        {
            title: '序号',
            width: 80,
            render: (text, record, index) => `${index + 1}`,
            fixed: "left"
        }, {
            title: '人员名称',
            width: 130,
            dataIndex: 'userName',
            render: (text, record) => <span onClick={() => onLook(record)} className="click-span">{text}</span>
        }, {
            title: '所属部门',
            width: 130,
            dataIndex: 'orgName',
        },
        {
            title: '餐厅',
            width: 130,
            dataIndex: 'canteenName',
        },
        {
            title: '报餐次数',
            width: 130,
            dataIndex: 'total'
        },
        {
            title: '超时报餐次数',
            width: 130,
            dataIndex: 'overtimeBookingTotal'
        },

        {
            title: '取消用餐次数',
            width: 130,
            dataIndex: 'canceled',
        },
        {
            title: '超时取消用餐次数',
            width: 130,
            dataIndex: 'overtimeCanceledTotal',
        },
        {
            title: '失约次数',
            width: 130,
            dataIndex: 'overdue',
        }
    ];

    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id: number) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === id) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([id as number]);
        }
    };

    return (
        <Table
            rowKey={record => record.userId as number}
            // rowSelection={{
            //     type: "radio",
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: IBreakingDetial[]) => {
            //         setSelectedRowKeys(selectedRowKeys as string[]);
            //     }
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({pageNum, pageSize})
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.userId as number)
            })}
            scroll={{x: 'max-content', y: 500}}
        />
    )
}
