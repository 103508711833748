import {createModel} from "hox";
import {useState, useEffect} from "react";
import {IMealSearch, IMealDetial, IMealFields} from "./mealTypes"
import {TablePaginationConfig} from "antd/es/table/interface";
import {DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE} from "@/utils/constants";
import {mealService} from "./mealService";
import {ISearchPage} from "@/types/AppTypes";
import {transPgToPagination} from "@/model/globalModel";
import {IMealTypeEnum, IMealTypeObj} from "@/types/AppEnums";
import {globalPrompt} from "@/components/message";
import {useLockFn} from "ahooks";
import {IOrgs} from "@/views/org/orgTypes";
import {orgService} from "@/views/org/orgService";

interface IMealType {
    visible: boolean,
    mode: "add" | "edit"
}

interface IOrderInCalel {
    startTimeBook: string,
    endTimeBook: string,
    cancelMealTime: string
}

interface IMealTimeType {
    startTimeBusiness: string,
    endTimeBusiness: string
}

interface IMealTimeTypeList {
    "breakfast": IMealTimeType
    "lunch": IMealTimeType
    "dinner": IMealTimeType

    [key: string]: IMealTimeType
}

export interface IOrgTimeItem {
    orgName: string, // "yh.tj",
    orgCode: string, //"天津区域管理公司",
    startTime: string, //"1100",
    endTime: string, // "1200"
}

export const useMealModel = createModel(function () {
    //列表数据源
    const [dataSource, setDataSource] = useState<IMealDetial[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IMealFields>({});
    //抽屉框
    const initShopDrawer: IMealType = {visible: false, mode: "add"}
    const [visibleDrawer, setVisibleDrawer] = useState<IMealType>(initShopDrawer);
    const setIVisibleDrawer = (str?: "add" | "edit") => setVisibleDrawer(str ? {
        visible: true,
        mode: str
    } : initShopDrawer);

    // 机构缓存
    const [orgs, setOrgs] = useState<IOrgs[]>([]);
    useEffect(() => {
        if (visibleDrawer.visible) {
            if (orgs == null || orgs.length <= 0) {
                // 加载机构
                initOrg()
            }

        }
    }, [visibleDrawer.visible]);


    async function initOrg() {
        //机构信息
        const {retData: orgData} = await orgService.tree();
        setOrgs(orgData)
    }

    const getOrgOptions =()=>{
        const mapFun=(item: IOrgs) :any=> {
            let newOption= {
                title: item.name,
                value: item.code,
                key: item.code,
                children: null,
            }

            if (item.children && item.children.length>0) {
                // @ts-ignore
                newOption.children = item.children.map(mapFun)
            }
            return newOption;
        }

        let orgOptions = orgs.map(mapFun);
        return  orgOptions;
    }
    const getOrgCodeNameMap=()=>{
            let map = new Map<string,string>();
        const forFun=(item: IOrgs) :any=> {
            if (item.code && item.name) {
                map.set(item.code,item.name);
                if (item.children && item.children.length>0) {
                    item.children.forEach(forFun)
                }
            }
        }
         orgs.forEach(forFun);
        return  map;
    }



    // const
    //分页参数
    const initPgCfg: TablePaginationConfig = {pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM}
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const loadData = async (page?: ISearchPage, searchValue: IMealFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let {retData} = await mealService.page(searchParams);
        let {data, ...pg} = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(data)
    }
    //设置搜索value
    const updateSearchParam = (updateParam: IMealFields) => {
        setSearchParam({...searchParam, ...updateParam})
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //新增参数
    const [addFormVal, setAddFormVal] = useState<IMealDetial>({})
    //编辑参数
    const [editFormVal, setEditFormVal] = useState<IMealDetial>({})
    //点餐时间加取消用餐缓存
    const IInitOrderInCalel = {
        startTimeBook: "",
        endTimeBook: "",
        cancelMealTime: ""
    }
    const [orderInCalel, setOrderInCalel] = useState<IOrderInCalel>(IInitOrderInCalel)
    //餐别缓存
    const [mealTypeList, setMealTypeList] = useState<IMealTypeEnum[]>([])
    //餐别时间缓存
    const initmealTimeList: IMealTimeTypeList = {
        "breakfast": {
            startTimeBusiness: "",
            endTimeBusiness: ""
        },
        "lunch": {
            startTimeBusiness: "",
            endTimeBusiness: ""
        },
        "dinner": {
            startTimeBusiness: "",
            endTimeBusiness: ""
        }
    }
    const [mealTimeList, setMealTimeList] = useState<IMealTimeTypeList>(initmealTimeList)

    //部门类别缓存
    const [orgTimeList, setOrgTimeList] = useState<IOrgTimeItem[]>([])

    //表单新增修改
    const updateAddFormVal = (val: IMealDetial | null) => {
        val ? setAddFormVal({...addFormVal, ...val}) : setAddFormVal({});
    }

    //表单新增修改
    const updateEditFormVal = (val: IMealDetial | null) => {
        // console.log({ ...editFormVal, ...val }, val)
        val ? setEditFormVal({...editFormVal, ...val}) : setEditFormVal({});
    }
    const insertDetail = useLockFn(async (params: IMealFields) => {
        let res = null
        let findMealTimeKey = Object.keys(mealTimeList).find(item => mealTypeList.indexOf(item as IMealTypeEnum) !== -1 && (!!!mealTimeList[item].startTimeBusiness || !!!mealTimeList[item].endTimeBusiness))
        let {startTimeBook, endTimeBook, cancelMealTime} = orderInCalel
        if (findMealTimeKey) {
            return globalPrompt("message", {text: `请选择${IMealTypeObj[findMealTimeKey]}营业时间`, type: "warning"})
        }
        if (!!!startTimeBook || !!!endTimeBook || !!!cancelMealTime) {
            return globalPrompt("message", {text: `请补全点餐时间，取消用餐时间`, type: "warning"})
        }
        for (let i = 0; i < mealTypeList.length; i++) {
            // debugger;
            // console.log(mealTimeList[mealTypeList[i]])
            // let { retData } = await mealService.insert({ ...params, ...orderInCalel,orgTimeList, mealType: mealTypeList[i], ...mealTimeList[mealTypeList[i]] });
            let {retData} = await mealService.insert({
                ...params, ...orderInCalel,
                orgTimeList, ...mealTimeList[mealTypeList[i]]
            });
            if (i === mealTypeList.length - 1) {
                res = retData;
            }
        }
        if (res) {
            loadData();
            setIVisibleDrawer();
            setOrgTimeList([])
            globalPrompt("message", {text: "餐别新增成功", type: "success"})
        }
    })
    const editDetail = useLockFn(async (params: IMealFields) => {
        let {id} = editFormVal
        let {startTimeBook, endTimeBook, cancelMealTime} = orderInCalel
        if (!!!startTimeBook || !!!endTimeBook || !!!cancelMealTime) {
            return globalPrompt("message", {text: `请补全点餐时间，取消用餐时间`, type: "warning"})
        }
        let {retData} = await mealService.update({
            ...params,
            id,
            mealType: params.mealType?.toString(), ...orderInCalel,
            orgTimeList, ...mealTimeList[mealTypeList[0]]
        })
        if (retData) {
            loadData();
            setIVisibleDrawer();
            setOrgTimeList([])
            globalPrompt("message", {text: "餐别编辑成功", type: "success"})
        } else {
            globalPrompt("message", {text: "餐别编辑失败", type: "success"})

        }
    })
    //删除餐厅
    const removeDetail = useLockFn(async (id: string) => {
        let {retData} = await mealService.remove(id)
        if (retData) {
            loadData();
            globalPrompt("message", {text: "餐厅删除成功", type: "success"})
        } else {
            globalPrompt("message", {text: "餐厅删除失败", type: "success"})

        }
    })

    //


    return {
        dataSource,
        searchParam,
        initPgCfg,
        pgCfg,
        selectedRowKeys,
        visibleDrawer,
        setDataSource,
        setSearchParam,
        setPgCfg,
        loadData,
        updateSearchParam,
        resetSearchParam,
        setSelectedRowKeys,
        setVisibleDrawer,
        setIVisibleDrawer,
        updateAddFormVal,
        updateEditFormVal,
        addFormVal, setAddFormVal,
        editFormVal, setEditFormVal,
        mealTypeList, setMealTypeList,
        orderInCalel, setOrderInCalel,
        mealTimeList, setMealTimeList,
        initmealTimeList,
        insertDetail,
        editDetail,
        removeDetail,
        orgTimeList, setOrgTimeList,
        orgs, setOrgs,
        getOrgOptions,
        getOrgCodeNameMap,
        initOrg,
    }
})
