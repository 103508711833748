import React from "react";
import { useRestaurantModel } from "../restaurantModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import useGlobalModel from "@/model/globalModel";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useRestaurantModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            span: 4,
            placeholder: "餐厅名称",
            fieldName: "name",
            size: "small",
        },
    ]
    return (
        <YhPageHeader
            title="餐厅管理"
            triggerSearch={true}
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    );
}