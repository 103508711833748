import React from "react";
import { useUserModel } from "../userModel";


import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import useGlobalModel from "@/model/globalModel";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useUserModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            span: 4,
            placeholder: "用户姓名",
            fieldName: "name",
            size: "small",
        },
    ]
    return (
        <YhPageHeader
            title="用户管理"
            triggerSearch={true}
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    );
}