import React, { useEffect } from "react";
import { YHModal } from "@/components/YHModal";
import { YhBox } from "@/components/YhBox";
import { useReleaseModel } from "../releaseModel";
import { Button, Table } from "antd";
import { momentFormat } from "@/utils/utils";
import { getBaseImgLookUrl } from "@/utils/constants";

export const FoodModel = () => {
    const {
        visibleModel: visible, setVisibleModel, lookListData
    } = useReleaseModel();

    const onOK = () => {
        onCancel()
    }
    const onCancel = () => {
        setVisibleModel(false)
    }

    return <YHModal
        visible={visible}
        onCancel={onCancel}
        onOk={onOK}
        title="当日菜品明细"
        width={1100}
        bodyHeight={700}
        showFooter={true}
    >
        <div className="food">
            {lookListData.map((item, index) => {
                    return (
                        <div key={index} className="food-item">
                            <div className="img">
                                <img className="img-detail" src={getBaseImgLookUrl(item.image as string)} alt={item.name} />
                            </div>
                            <div className="message">
                                <span className="name">{item.name}</span>
                                <p className="other">
                                    <span>口感：{item.labelName}</span>
                                    <span>累计：{item.orderNum}</span>
                                </p>
                            </div>
                        </div>
                    )
                })
            }
        </div>
    </YHModal>
}