import React, {useRef} from "react";
import {YHDrawer} from "@/components/YhDrawer";
import {YhForm} from "@/components/YhFormBox";
import {FormItemTypeEnum, IYhForm, IYhFormRef} from "@/components/YhFormBox/Types";
import {IOrgTimeItem, useMealModel} from "../mealModel";
import {IMealDetial} from "../mealTypes";
import moment from 'moment';
import "./meal.less"
import {message, TimePicker, Tree} from "antd";
import useGlobalModel, {transOptions} from "@/model/globalModel";
import {IMealTypeOptions} from "@/types/AppEnums";
import {getFormValidatorErrText} from "@/utils/utils";
import {useDictModel} from "@/views/dict/dictModel";

const {RangePicker} = TimePicker


export enum IMealTypeEnum {
    //早餐
    BREAKFAST = "breakfast",
    //中餐
    LUNCH = "lunch",
    //晚餐
    DINNER = "dinner"
}

// let queryConDitions: {
//     data: [{ name: "郝磊" }],
//     customersType: "星期五"
// }
export function DetailDrawer() {
    const {dataRestaurantSource} = useGlobalModel()
    const {getSelectedDictOne} = useDictModel()
    const {
        visibleDrawer: {visible, mode},
        setVisibleDrawer,
        setIVisibleDrawer,
        addFormVal,
        editFormVal,
        mealTypeList,
        updateAddFormVal,
        updateEditFormVal,
        setMealTypeList,
        orderInCalel, setOrderInCalel,
        mealTimeList, setMealTimeList,
        orgTimeList, setOrgTimeList,
        insertDetail,
        editDetail,
        initmealTimeList,
        setAddFormVal,
        setEditFormVal,
        getOrgOptions,
        getOrgCodeNameMap,
    } = useMealModel()
    const formRef = useRef<IYhFormRef>();
    const onCancel = () => {
        setIVisibleDrawer()
        setMealTimeList(initmealTimeList)
        setMealTypeList([])
        mode === "edit" ? setEditFormVal({}) : setAddFormVal({})
    }
    const onSubmit = () => {
        console.log(orderInCalel, mealTimeList)
        formRef.current?.validateFields().then((val: any) => {
            let {orgType, ...params} = val
            mode === "edit" ? editDetail(params) : insertDetail(params)
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }
    const onChange = (changedValues: { [key: string]: any }) => {
        if ("mealType" in changedValues) {
            setMealTypeList([changedValues.mealType])
        }
        mode == "add" ? updateAddFormVal(changedValues) : updateEditFormVal(changedValues)
    }
    // const chageOrgType = (value: string, option: any) => {
    //     if (option) {
    //         let newOption: IOrgTimeItem[] = orgTimeList
    //         let {title, value} = option
    //         let isSelect = orgTimeList.find(item => item.orgCode === value)
    //         if (!!!isSelect) {
    //             newOption.push({
    //                 orgName: title,
    //                 orgCode: value,
    //                 startTime: "",
    //                 endTime: ""
    //             })
    //         } else {
    //             newOption = newOption.filter(item => item.orgCode !== value)
    //         }
    //         setOrgTimeList(newOption)
    //     }
    // }

    // const OrgTreeOnChange = (value: string[], labelList: React.ReactNode[], extra: ChangeEventExtra) => {
    //
    //     if (null == value || value.length <= 0) {
    //         setOrgTimeList([])
    //         return
    //     }
    //     // 已经添加过的。转成map
    //     let selectdOrgMap: Map<string, IOrgTimeItem> = orgTimeList.reduce((acc, obj) => {
    //         acc.set(obj.orgCode, obj);
    //         return acc;
    //     }, new Map<string, IOrgTimeItem>())
    //
    //
    //     // 新增的项目
    //     let newItemList = value.filter(i => null == selectdOrgMap.get(i))
    //
    //     //删掉的项目
    //     let delItemList = Object.keys(selectdOrgMap).filter(i => !value.concat(i));
    //
    //
    //     let newOption: IOrgTimeItem[] = orgTimeList
    //
    //     if (newItemList && newItemList.length > 0) {
    //         newItemList.forEach(code => {
    //             let name = orgCodeNameMap.get(code);
    //             if (name) {
    //                 newOption.push({
    //                     orgName: name,
    //                     orgCode: code,
    //                     startTime: "",
    //                     endTime: ""
    //                 })
    //             }
    //         })
    //     }
    //     if (delItemList && delItemList.length > 0) {
    //         newOption = newOption.filter(i => !delItemList.concat(i.orgCode))
    //     }
    //     setOrgTimeList(newOption)
    // }

    // 机构选择框
    const orgSelectOptions = getOrgOptions();

    const orgCodeNameMap = getOrgCodeNameMap();

    const formConfig: IYhForm<IMealDetial> = {
        formRef,
        labelCol: {span: 6},
        formValues: mode === "add" ? addFormVal : {
            ...editFormVal,
            mealType: editFormVal?.mealType ? editFormVal.mealType.split(",") : []
        },
        onChange,
        items: [
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "餐别名称",
                fieldName: "mealType",
                span: 24,
                // selectMode: "multiple",
                options: IMealTypeOptions,
                disable: mode === "edit" ? true : false,
                rules: [{required: true, message: "请输入餐别名称"}]
            },
            {
                type: FormItemTypeEnum.Select,
                fieldLabel: "餐厅名称",
                fieldName: "canteenId",
                span: 24,
                options: transOptions(dataRestaurantSource),
                disable: mode === "edit" ? true : false,
                rules: [{required: true, message: "请输入餐厅名称"}]
            },
            // {
            //     type: FormItemTypeEnum.TreeSelect,
            //     fieldLabel: "部门",
            //     fieldName: "orgType",
            //     span: 24,
            //     // onTreeSelectChange: chageOrgType,
            //     treeData: orgSelectOptions,
            //     onChange: OrgTreeOnChange,
            //     treeCheckable: true,
            //     allowClear: true,
            //     multiple: true,
            //     rules: [{required: true, message: "请选择部门"}]
            // },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "餐别备注",
                fieldName: "note",
                span: 24,
            },
        ]
    }
    const onChage = (time: any, timeString: any) => {
        console.log(time, timeString)
    }
    const MealChina: any = {
        breakfast: "早餐",
        lunch: "中餐",
        dinner: "晚餐"
    }
    const onOrgChage = (timeSting: string[], item: IOrgTimeItem) => {
        console.log("onOrgChage")

        let neworgTimeList = [...orgTimeList]
        let newItem = {...item, startTime: timeSting[0], endTime: timeSting[1]}

        neworgTimeList = neworgTimeList.filter(i => i.orgCode !== item.orgCode)
        neworgTimeList.push(newItem)
        setOrgTimeList(neworgTimeList)
    }
    return (
        <YHDrawer
            {...{
                title: `餐别管理${mode === 'add' ? '新增' : '编辑'}`,
                visible,
                onCancel,
                width: 900,
                okText: "保存",
                onOk: onSubmit
            }}
        >
            <YhForm {...formConfig} />
            <div className="time">
                <div className="time-tabs">
                    <span className="time-tabs-item">时间设置</span>
                </div>
                <div className="time-step">
                    <div className="time-step-item">
                        <div className="title">一、点餐时间</div>
                        <div className="content">
                            {/* defaultValue={[moment('3:00 凌晨'), moment('7:00 晚上')]} */}
                            1、当日餐当日点，设置当日点餐时间 <RangePicker
                            defaultValue={orderInCalel.startTimeBook && orderInCalel.endTimeBook ? [moment(orderInCalel.startTimeBook, 'HH:mm'), moment(orderInCalel.endTimeBook, 'HH:mm')] : null}
                            format="HH:mm"
                            bordered={false}
                            size="small"
                            style={{width: 240}}
                            onChange={(time: any, timeSting: string[]) => {
                                setOrderInCalel({
                                    ...orderInCalel,
                                    startTimeBook: timeSting[0],
                                    endTimeBook: timeSting[1]
                                })
                            }}/>
                        </div>
                    </div>
                    <div className="time-step-item">
                        <div className="title">二、取消用餐时间</div>
                        <div className="content">
                            1、每日<TimePicker
                            defaultValue={orderInCalel.cancelMealTime ? moment(orderInCalel.cancelMealTime, 'HH:mm') : undefined}
                            format="HH:mm"
                            bordered={false}
                            size="small"
                            style={{width: 120}}
                            onChange={(time: any, timeSting: string) => {
                                setOrderInCalel({...orderInCalel, cancelMealTime: timeSting})
                            }}/>之前可以取消当日用餐
                        </div>
                    </div>
                    <div className="time-step-item">
                        <div className="title" onClick={() => console.log(mealTypeList)}>三、用餐时间段</div>
                        <div className="content" onClick={() => console.log(mealTypeList)}>
                            {
                                // mealTimeList
                                Object.keys(mealTimeList).map(item => {
                                    return (
                                        mealTypeList.indexOf(item as IMealTypeEnum) !== -1 &&
                                        <p>{MealChina[item]}营业时间<RangePicker
                                            format="HH:mm"
                                            bordered={false}
                                            size="small"
                                            style={{width: 240}}
                                            defaultValue={mealTimeList[item]['startTimeBusiness'] && mealTimeList[item]['endTimeBusiness'] ? [moment(mealTimeList[item]['startTimeBusiness'], 'HH:mm'), moment(mealTimeList[item]['endTimeBusiness'], 'HH:mm')] : null}
                                            onChange={(time: any, timeSting: string[]) => {
                                                setMealTimeList({
                                                    ...mealTimeList,
                                                    [item]: {
                                                        startTimeBusiness: timeSting[0],
                                                        endTimeBusiness: timeSting[1]
                                                    }
                                                })
                                            }}
                                        /></p>
                                    )
                                })
                            }
                        </div>
                    </div>
                    <div className="time-step-item">
                        <div className="title" onClick={() => console.log(mealTypeList)}>四、部门用餐时间段</div>
                        <div className="content">
                            <Tree
                                treeData={orgSelectOptions}
                                showLine
                                titleRender={(node: any) => {
                                    let code = node.key;
                                    // 已经添加过的。转成map
                                    let orgTimeMap: Map<string, IOrgTimeItem> = orgTimeList.reduce((acc, obj) => {
                                        acc.set(obj.orgCode, obj);
                                        return acc;
                                    }, new Map<string, IOrgTimeItem>())

                                    // 整理数据
                                    let item: IOrgTimeItem = {
                                        orgCode: node.key,
                                        orgName: node.title,
                                        startTime: "",
                                        endTime: ""
                                    }
                                    let orgTimeItem = orgTimeMap.get(code);
                                    if (orgTimeItem) {
                                        item.startTime = orgTimeItem.startTime
                                        item.endTime = orgTimeItem.endTime
                                    }
                                    return (
                                        <p key={item.orgCode}>{item.orgName} 营业时间<RangePicker
                                            format="HH:mm"
                                            bordered={false}
                                            size="small"
                                            style={{width: 240}}
                                            defaultValue={item['startTime'] && item['endTime'] ? [moment(item['startTime'], 'HH:mm'), moment(item['endTime'], 'HH:mm')] : null}
                                            onChange={(time: any, timeSting: string[]) => onOrgChage(timeSting, item)}
                                            // onChange={(time: any, timeSting: string[]) => {
                                            //     setMealTimeList({ ...mealTimeList, [item]: { startTimeBusiness: timeSting[0], endTimeBusiness: timeSting[1] } })
                                            // }}
                                        /></p>
                                    )

                                }}
                            />

                        </div>
                    </div>

                    {/*<div className="time-step-item">*/}
                    {/*    <div className="title" onClick={() => console.log(mealTypeList)}>四、部门用餐时间段</div>*/}
                    {/*    <div className="content" onClick={() => console.log(mealTypeList)}>*/}
                    {/*        {*/}
                    {/*            // mealTimeList*/}
                    {/*            orgTimeList.map((item, index) => {*/}
                    {/*                return (*/}
                    {/*                    <p key={index}>{item.orgName}营业时间<RangePicker*/}
                    {/*                        format="HH:mm"*/}
                    {/*                        bordered={false}*/}
                    {/*                        size="small"*/}
                    {/*                        style={{width: 240}}*/}
                    {/*                        defaultValue={item['startTime'] && item['endTime'] ? [moment(item['startTime'], 'HH:mm'), moment(item['endTime'], 'HH:mm')] : null}*/}
                    {/*                        onChange={(time: any, timeSting: string[]) => onOrgChage(timeSting, item)}*/}
                    {/*                        // onChange={(time: any, timeSting: string[]) => {*/}
                    {/*                        //     setMealTimeList({ ...mealTimeList, [item]: { startTimeBusiness: timeSting[0], endTimeBusiness: timeSting[1] } })*/}
                    {/*                        // }}*/}
                    {/*                    /></p>*/}
                    {/*                )*/}
                    {/*            })*/}
                    {/*        }*/}
                    {/*    </div>*/}
                    {/*</div>*/}
                </div>
            </div>
        </YHDrawer>
    )
}
