import React from "react";
import { DataTable } from "./DataTable";
import { Button, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useMealReportModel } from "../mealReportModel";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
export const DataBox = () => {
    const { visibleDrawer, setVisibleDrawer, loadData, listExport, descTotal } = useMealReportModel();

    const title = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => listExport().then()}
        >导出</Button>
    </>
    const desc: IDescription[] = [
        {
            label: "部门数",
            value: descTotal.orgTotal,
            color: DescriptionColorEnum.red
        },
        {
            label: "报餐人数",
            value: descTotal.bookingTotal,
            color: DescriptionColorEnum.red
        },
        {
            label: "就餐人数",
            value: descTotal.finishedTotal,
            color: DescriptionColorEnum.red
        },
        {
            label: "取消人数",
            value: descTotal.canceledTotal,
            color: DescriptionColorEnum.red
        },
        {
            label: "失约人数",
            value: descTotal.overdueTotal,
            color: DescriptionColorEnum.red
        },
        {
            label: "失约率",
            value: descTotal.overdueRate + "%",
            color: DescriptionColorEnum.red
        }
    ]
    return (
        <YhBox title={title} descriptions={desc}>
            <DataTable />
        </YhBox>
    )
}
