import React, { FC } from "react";
import { IFormItem, ISelectOption } from "../Types";
import { Form, Select } from "antd";

export const __FormSelect: FC<IFormItem> = ({ placeholder, fieldLabel, fieldName, selectMode, initWidth, ...props }) => {
    return (
        <Form.Item
            label={fieldLabel}
            name={fieldName}
            rules={props.rules}
            labelCol={props.labelCol}
        >
            <Select
                mode={selectMode}
                // maxTagCount={1}
                placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
                showSearch={props.showSearch ?? true}
                optionFilterProp="label"
                style={{ width: initWidth ?? "100%" }}
                allowClear={props.allowClear}
                options={props.options}
                disabled={props.disable}
                onChange={(value: string, option) => props.onSelectChange?.(value, option as ISelectOption)}
                onFocus={() => props.onSelectFocus?.()}
            />
        </Form.Item>
    )
}