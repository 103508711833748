import React from "react";
import { Button, Form } from "antd";
import { YhForm } from "@/components/YhFormBox";
import { FormItemTypeEnum, IYhForm } from "@/components/YhFormBox/Types";
import { useDictModel } from "../dictModel";
import QRCode from 'qrcode.react'

export const FormBox = () => {
    const { mode, setSelectedNodes, onSubmit, formVal, onChangeFormVal } = useDictModel();

    const formConfig: IYhForm = {
        labelCol: { span: 6 },
        formValues: formVal,
        onChange: onChangeFormVal,
        items: [
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "父级编码",
                fieldName: "pcode",
                span: 18,
                disable: true
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "编码",
                fieldName: "code",
                span: 18,
                //disable: mode === "edit" ? true : false,
                rules: [{ required: true, message: "该项为必填项" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "名称",
                fieldName: "name",
                span: 18,
                rules: [{ required: true, message: "该项为必填项" }]
            }, {
                type: FormItemTypeEnum.Input,
                fieldLabel: "备注",
                fieldName: "note",
                span: 18,
            }, {
                type: FormItemTypeEnum.Switch,
                fieldLabel: "状态",
                fieldName: "status",
                span: 18,
            },
        ]
    };

    // 二维码下载
    const changeCanvasToPic = () => {
        const canvasImg = document.getElementById('qrCode') as HTMLCanvasElement; // 获取canvas类型的二维码
        const img = new Image();
        img.src = canvasImg?.toDataURL('image/png'); // 将canvas对象转换为图片的data url
        const downLink = document.getElementById('down_link') as any;
        downLink.href = img.src;
        downLink.download = formVal.name; // 图片name
    };


    return (
        <>
            <div className="dict-list-header">
                <Button type="link" shape="round" onClick={onSubmit}>保存</Button>
                <Button type="link" shape="round" onClick={() => setSelectedNodes([])}>取消</Button>
            </div>
            <div className="dict-from-content">
                <YhForm {...formConfig} />
                <Form.Item label="部门二维码:" labelCol={{ span: 5 }}>
                    <QRCode
                        id="qrCode"
                        // 测试门户账号
                        value={`&code=${formVal.code}&name=${formVal.name}&userName=reportAdmin&userPassword=123456&userType=customer`}
                        // 生产门户账号
                        // value={`&code=${formVal.code}&name=${formVal.name}&userName=reportAdmin&userPassword=123456`}
                        size={200}
                    />
                    <a id="down_link" onClick={changeCanvasToPic}>
                        点击下载
                    </a>
                </Form.Item>
            </div>
        </>
    )
}