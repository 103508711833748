import React from 'react';
import { TabBox } from "./TabBox";
import { CalendarBox } from "./CalenderBox";
import { MealBox } from "./MealBox"
import "./release.less"
import { FoodModel } from './foodModel';
export function Release() {
    return (
        <>
            <TabBox />
            <div style={{ marginTop: "15px", display: "flex" }}>
                <CalendarBox />
                <MealBox />
                <FoodModel/>
            </div>
        </>
    );
}


