import React, {useEffect} from "react";
import {useBreakingModel} from "../breakingModel";
import {ISearchValue, SearchTypeEnum} from "@/components/YhPageHeader/Types";
import {YhPageHeader} from "@/components/YhPageHeader";
import useGlobalModel, {transOptions} from "@/model/globalModel";
import {useDictModel} from "@/views/dict/dictModel";
import {DictCodeEnmu} from "@/types/AppDictEnums";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,

        loadData
    } = useBreakingModel();
    const {dataRestaurantSource} = useGlobalModel()
    const {getSelectedDictOne} = useDictModel()
    useEffect(() => {
        // console.log(dataRestaurantSource)
    })
    const searchFields = [
        // {
        //     type: SearchTypeEnum.Select,
        //     span: 4,
        //     placeholder: "状态",
        //     fieldName: "mealStatus",
        //     options: [
        //         {label: "完成", value: "finished"},
        //         {label: "取消", value: "canceled"},
        //         {label: "未完成", value: "unfinished"},
        //         {label: "失约", value: "overdue"},
        //         {label: "评价", value: "evaluated"},
        //     ]
        // },

        {
            type: SearchTypeEnum.Input,
            span: 4,
            placeholder: "人员名称",
            fieldName: "userName",
        },
        {
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "部门名称",
            fieldName: "orgCode",
            options: getSelectedDictOne(DictCodeEnmu.SUOSHUJIGOU)
        },
        {
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "餐厅名称",
            fieldName: "canteenId",
            options: transOptions(dataRestaurantSource)
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
    ]
    return (
        <YhPageHeader
            title="人员统计"
            triggerSearch={true}
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    );
}
