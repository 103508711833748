import { httpRequest } from "../../utils/HttpRequest";
import { IMealPage } from "@/types/AppTypes";
import { IMealFields, IMealSearch, IMealDetial } from "./mealTypes"
class MealService {
    async page(searchVo: IMealSearch) {
        const apiUrl = "/common/meal/page";
        return await httpRequest.post<IMealPage<IMealDetial>>(apiUrl, searchVo);
    }
    async insert(searchVo: IMealDetial) {
        const apiUrl = "/common/meal/insert";
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    async one(id: string) {
        const apiUrl = `/common/meal/one/${id}`;
        return await httpRequest.get<IMealDetial>(apiUrl);
    }
    async update(searchVo: IMealDetial) {
        const apiUrl = "/common/meal/update";
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    async remove(code: string) {
        const apiUrl = `/common/meal/remove/${code}`;
        return await httpRequest.get<boolean>(apiUrl);
    }
}
export const mealService = new MealService();
