import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { DetailDrawer } from "./DetailDrawer"
export const Library = () => {
    return (
        <>
            <PageHeader />
            <DataBox />
            <DetailDrawer />
        </>
    )
}