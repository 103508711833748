import React from "react";
import { DataTable } from "./DataTable";
import { Button, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useMealDetailModel } from "../mealDetailModel";
export const DataBox = () => {
    const { listExport } = useMealDetailModel();

    const title = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => listExport().then()}
        >导出</Button>
    </>
    return (
        <YhBox title={title}>
            <DataTable />
        </YhBox>
    )
}