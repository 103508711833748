import {
	IMealReportFields,
	IMealReportDetial,
	IMealReportSearch,
	IMealReportList,
} from './mealReportType';
import { IMealPage } from '@/types/AppTypes';
import { httpRequest } from '@/utils/HttpRequest';
class MealReportService {
	async page(searchVo: IMealReportSearch) {
		const apiUrl = '/booking/statistics';
		return await httpRequest.post<IMealReportList>(apiUrl, searchVo);
	}
	async export(searchVo: IMealReportFields) {
		const apiUrl = 'booking/statistics/export';
		return await httpRequest.postFile<boolean>(apiUrl, searchVo);
	}
}
export const mealReportService = new MealReportService();
