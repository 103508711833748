import React, { useEffect } from "react";
import { Button, Tree, message } from "antd";
import { YhBox } from "@/components/YhBox";
import { FormBox } from "./FormBox";
import { EventDataNode, DataNode } from "antd/lib/tree";
import { useDictModel } from "../dictModel";
import { IMEALDictDetail } from "../dictService";
import { YhAuth } from "@/components/auth/YhAuth";

export const DataBox = () => {
    const { treeSource, loadData, setMode, setSelectedNodes, selectedNodes, setFormVal, removeOneDict } = useDictModel();

    useEffect(() => {
        loadData();
    }, [])


    //字典选中事件
    const onSelect = (selectedKeys: React.Key[], info: {
        event: "select";
        selected: boolean;
        // @ts-ignore
        node: EventDataNode;
        selectedNodes: DataNode[];
        nativeEvent: MouseEvent;
    }) => {
        let selectNode = info.selectedNodes as IMEALDictDetail[];
        // console.log(selectNode[0])
        setMode(info.selected ? "edit" : "");
        setSelectedNodes(selectNode);
        setFormVal(selectNode[0]);
    };


    const action = <>
        {treeSource.length <= 0 && <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => setMode("root")}
        >增加根级</Button>}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => {
                if (!selectedNodes.length) {
                    message.warning("请选择要增加的父级");
                    return;
                }
                message.success("增加下级模式开启");
                setMode("nextAdd");
            }}
        >增加下级</Button>

        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => {
                if (!selectedNodes.length) {
                    message.warning("请选择要增加的同级");
                    return;
                }
                message.success("增加同级模式开启");
                setMode("add");
            }}
        >增加同级</Button>
    </>;

    return (
        <YhBox title="" action={action}>
            <div className="dict-container flex">
                <div className="dict-list-box">
                    <Tree
                        defaultExpandedKeys={[selectedNodes[0]?.key]}
                        selectedKeys={[selectedNodes[0]?.key]}
                        titleRender={(node: any) => `${node.name}`}
                        onSelect={onSelect}
                        treeData={treeSource}
                    />
                </div>
                <div className="dict-from-box flex">
                    {selectedNodes.length > 0 ?
                        <FormBox />
                        :
                        <div className="dict-init-box flex flex-1 items-center justify-center">数据字典</div>
                    }
                </div>
            </div>
        </YhBox>
    )
}
