import React, { useEffect } from 'react';
import { Route, Switch, Redirect } from "react-router-dom";
import useGlobalModel from "./model/globalModel";
import { Login } from "./views/login/Login";
import Index from "./layout";
import { setToken } from './utils/constants';
import TokenLogin from './views/login/TokenLogin'
import { urlParse } from "@/utils/utils";


function App() {
    const { user: { token } } = useGlobalModel();


    function loginCheck() {
        // const url = 'https://localhost:3000?token=90d587565ba24df99d4f804fb931d2c8'
        const url = window.location.href
        let urlObj = urlParse(url);

        if (urlObj.token) {
            setToken(urlObj.token)
            return <TokenLogin token={urlObj.token} />
        }
        return <Login />
    }

    return (
        <Switch>
            <Route path="/login" component={() => loginCheck()} />
            <Route path="/" render={
                () => token ? <Index /> : <Redirect to="/login" />}
            />
            {/* <Route path="/" render={
                () => <Index />}
            /> */}
        </Switch>
    );
}

export default App;
