import useStorageState from "../hooks/useStorageState";
import arrayMove from 'array-move';
import { forFind } from "../utils/utils";
import menuData from "../data/menuData.json";
import { createModel } from "hox";

//定义页面需要通过路由传的参数
export type RouterParamType = {
    id: string,
    mode: string | "edit" | "add" | "look",
    [key: string]: any
}

//定义tabs参数类型
export type NavTabType = {
    id: string,
    path: string,
    name: string,
    closable: boolean,
    state?: RouterParamType,
    parent?: string | null,
    isShow?: boolean,
    // children?: null
}

// Nav模型定义
export type NavModelType = {
    navTabs: NavTabType[],
    selectedNavId: string,
    selectNav: (id: string) => void,
    closeNav: (id: string) => void,
    addNav: (id: string, state?: RouterParamType) => void,
    replaceNav: (closeId: string, replaceId: string, state?: RouterParamType, skip?: boolean) => void
    getSelectedNavState: () => RouterParamType | undefined
    initNav: () => void,
    sortNav?: (obj: any) => void,
    findNav: (id: string) => NavTabType | undefined,
    switchNavState: (id: string, state: RouterParamType) => void
}
let initNavTabs: NavTabType[] = [{
    id: "1",
    path: "/",
    name: "首页",
    parent: null,
    closable: false
}]

function useModel(): NavModelType {
    const [navTabs, setNavTabs, resetNavTabs] = useStorageState<NavTabType[]>("navTabs", initNavTabs);
    const [selectedNavId, setSelectedNav, resetSelectedNav] = useStorageState<string>("navSelected", initNavTabs[0].id);
    //初始nav  用于清楚缓存回到最初始状态
    const initNav = (): void => {
        resetNavTabs()
        resetSelectedNav()
    }
    //选中nav
    const selectNav = (id: string): void => {
        if (selectedNavId !== id) {
            setSelectedNav(id);
        }
    };
    //添加nav
    const addNav = (id: string, state?: RouterParamType): void => {
        let findNav: NavTabType | undefined = navTabs.find(el => el.id === id);
        if (findNav) {
            setSelectedNav(findNav.id);
        } else {
            let { children, ...params } = forFind(menuData, id);
            let newNavTabs: NavTabType[] = [...navTabs];
            if (params) {
                newNavTabs.push({ ...params, state: state ?? null })
                setNavTabs(newNavTabs)
                setSelectedNav(params.id);
            }
        }
    }

    //关闭nav
    const closeNav = (id: string): void => {
        let newNavTabs: NavTabType[] = navTabs.filter(el => el.id !== id);
        setNavTabs(newNavTabs);
        if (selectedNavId === id) {
            setSelectedNav(newNavTabs[newNavTabs.length - 1].id);
        }
    }

    //替换nav

    const replaceNav = (closeId: string, replaceId: string, state?: RouterParamType, skip: boolean = false) => {
        let filterNavTabs = navTabs.filter(el => skip ? skip : el.id !== closeId);
        let findTabs = filterNavTabs.find(item => item.id === replaceId);
        if (findTabs) {
            //这里做state判断  是详情保存时跳转成编辑页签， 为防止存在做state替换
            if (state) {
                let findIndex = filterNavTabs.findIndex(item => item.id === replaceId);
                filterNavTabs.splice(findIndex, 1, { ...filterNavTabs[findIndex], state }); 
            }
            setNavTabs(filterNavTabs);
            setSelectedNav(replaceId);
        } else {
            let { children, ...params } = forFind(menuData, replaceId);
            filterNavTabs.push({ ...params, state: state ?? null })
            setNavTabs(filterNavTabs)
            setSelectedNav(params.id);
        }
    }

    //获取当前选中的nav的node的state
    const getSelectedNavState = () => {
        let findObj = navTabs.find(item => item.id === selectedNavId);
        return findObj ? findObj["state"] : undefined;
    }

    //根据id查找Nav是否存在
    const findNav = (id: string) => {
        return navTabs.find(item => item.id === id);
    }

    //已选中的nav替换state
    const switchNavState = (id: string, state: RouterParamType) => {
        let newNavTabs: NavTabType[] = [...navTabs];
        let findIndex = newNavTabs.findIndex(item => item.id === id);
        newNavTabs.splice(findIndex, 1, { ...newNavTabs[findIndex], state });
        setNavTabs(newNavTabs);
        setSelectedNav(id);
    }

    //nav排序
    const sortNav = ({ oldIndex, newIndex }: { oldIndex: number, newIndex: number }) => {
        let newTabs = arrayMove(navTabs, oldIndex, newIndex);
        setNavTabs(newTabs);
    }
    return {
        navTabs,
        selectedNavId,
        selectNav,
        closeNav,
        addNav,
        replaceNav,
        getSelectedNavState,
        sortNav,
        initNav,
        findNav,
        switchNavState
    }
}

export const useNavModel = createModel(useModel);
export default useNavModel;