import React from "react";
import { DataTable } from "./DataTable";
import { Button, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useUserModel } from "../userModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { importPropsConfig } from "@/utils/importFile";
import { AuthButton } from "@/components/auth/AuthButton";
import useGlobalModel from "@/model/globalModel";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
export const DataBox = () => {
    const { visibleDrawer,setVisibleDrawer,loadData } = useUserModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel();



    return (
        <YhBox>
            <DataTable />
        </YhBox>
    )
}