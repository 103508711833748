import { createModel } from "hox";
import { useState, useEffect } from "react";
import { ILibrarySearch, ILibraryDetial, ILibraryFields } from "./libraryTypes"
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { libraryService } from "./libraryService";
import { ISearchPage } from "@/types/AppTypes";
import { transPgToPagination } from "@/model/globalModel";
import { useLockFn } from "ahooks";
import { globalPrompt } from "@/components/message";
import { convertRes2Blob } from "@/utils/exportFile";
export interface ILibraryType {
    visible: boolean,
    mode: "add" | "edit"
}
export const useLibraryModel = createModel(function () {
    //列表数据源
    const [dataSource, setDataSource] = useState<ILibraryDetial[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<ILibrarySearch>({});
    //抽屉框
    const initShopDrawer: ILibraryType = { visible: false, mode: "add" }
    const [visibleDrawer, setVisibleDrawer] = useState<ILibraryType>(initShopDrawer);
    const setIVisibleDrawer = (str?: "add" | "edit") => setVisibleDrawer(str ? { visible: true, mode: str } : initShopDrawer);
    // const 
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [selectedRow, setSelectedRow] = useState<ILibraryDetial>({});
    const [md5FormVal, setMd5FormVal] = useState<string[]>([]) //图片md5值

    const loadData = async (page?: ISearchPage, searchValue: ILibrarySearch = searchParam) => {
        const searchParams = {
            ...searchValue,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await libraryService.page(searchParams);
        let { data, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(data)

    }
    //设置搜索value
    const updateSearchParam = (updateParam: ILibrarySearch) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //新增菜品
    const insertDetail = useLockFn(async (params: ILibraryFields) => {
        let { retData } = await libraryService.insert({ ...params, image: md5FormVal.toString() })
        if (retData) {
            loadData();
            setIVisibleDrawer();
            globalPrompt("message", { text: "菜品新增成功", type: "success" })
        } else {
            globalPrompt("message", { text: "菜品新增失败", type: "success" })

        }
    })
    //编辑菜品
    const editDetail = useLockFn(async (params: ILibraryFields) => {
        let { retData } = await libraryService.update({ ...params, id: selectedRowKeys[0], image: md5FormVal.toString() })
        if (retData) {
            loadData();
            setIVisibleDrawer();
            globalPrompt("message", { text: "菜品编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "菜品编辑失败", type: "success" })

        }
    })
    //删除菜品
    const removeDetail = useLockFn(async (id: string) => {
        let { retData } = await libraryService.remove(id)
        if (retData) {
            loadData();
            globalPrompt("message", { text: "菜品删除成功", type: "success" })
        } else {
            globalPrompt("message", { text: "菜品删除失败", type: "success" })

        }
    })
    //导出
    const listExport =async () => {
        let result = await libraryService.export(searchParam);
        convertRes2Blob(result)
    }
    return {
        dataSource,
        searchParam,
        initPgCfg,
        pgCfg,
        selectedRowKeys,
        visibleDrawer,
        setDataSource,
        setSearchParam,
        setPgCfg,
        loadData,
        updateSearchParam,
        resetSearchParam,
        setSelectedRowKeys,
        setVisibleDrawer,
        setIVisibleDrawer,
        selectedRow,
        setSelectedRow,
        insertDetail,
        editDetail,
        removeDetail,
        md5FormVal, setMd5FormVal,
        listExport

    }
})