import React, { ChangeEvent, FC, useEffect } from "react";
import { message, Modal } from "antd";
import useGlobalModel from "../../model/globalModel";
import { useHistory } from "react-router-dom";
import "./Login.scss";
import { defaultMdCmParam, CLIENT_APP_CODE } from "@/utils/constants";
import { loginServiceAuth2, Auth2MenuDetail } from "./LoginServiceAuth2";
import { ToolOutlined, SmileTwoTone } from "@ant-design/icons";
import { globalPrompt } from "@/components/message";
import "./config";

interface ITokenLogin {
	token: string;
}
declare const window: Window & { loginTimer: NodeJS.Timeout | null };

export const TokenLogin: FC<ITokenLogin> = ({ token }) => {
	const history = useHistory()
	const { user, setUser, setResourceAuth, setMenuAuth } = useGlobalModel();

	useEffect(() => {
		if (!window.loginTimer) {
			window.loginTimer = setTimeout(function () { beforeLogin() }, 1500);
		}
	}, [])

	const beforeLogin = async () => {
		let menuArr: string[] = [];
		function findMenuAll(array: Auth2MenuDetail[]) {
			array.forEach(item => {
				item.linkUrl && menuArr.push(item.linkUrl);
				if (item.children && item.children.length > 0)
					findMenuAll(item.children);
			})
		}

		try {
			let { retData, retMsg } = await loginServiceAuth2.VerifyInfo({ sourceApp: defaultMdCmParam.sourceApp, token });
			let { menus, resources, roles, shops, ...userInfo } = retData ?? {};

			setResourceAuth(resources ?? []);  //设置资源列表

			//设置用户菜单所有权限列表,(递归查询到所有要展示得菜单地址。)
			findMenuAll(menus?.[CLIENT_APP_CODE] ?? []);
			setMenuAuth(menuArr);


			setUser({ ...user, ...userInfo, token, shopId: "", shopName: "", loginType: 'account' });
			loginServiceAuth2.recordAppLoginInfo(CLIENT_APP_CODE, userInfo.userCode);
			globalPrompt("notification", {
				type: "success", title: "登录成功",
				duration: 3,
				text: <p className="bold"><span className="block"><SmileTwoTone /> {`您好!${userInfo.userName ?? ""}`}</span>欢迎使用云豪招标监测系统</p>
			});
			history.push("/");

		} catch (error) { }
	}

	return (
		<div className="login">
			<div className="caseBlanche">
				<div className="rond">
					<div className="test"></div>
				</div>
				<div className="load">
					<p>loading</p>
				</div>
			</div>
		</div>
	)

}

export default TokenLogin;