import { createModel } from "hox";
import { useState, useEffect } from "react";
import { IUserSearch, IUserDetial, IUserFields } from "./userType"
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { userService } from "./userService";
import { ISearchPage } from "@/types/AppTypes";
import { transPgToPagination } from "@/model/globalModel";
import { useLockFn } from "ahooks";
import { globalPrompt } from "@/components/message";

export const useUserModel = createModel(function () {
    //列表数据源
    const [dataSource, setDataSource] = useState<IUserDetial[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IUserFields>({});
    //抽屉框
    const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
    // const 
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const loadData = async (page?: ISearchPage, searchValue: IUserFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await userService.page(searchParams);
        let { data, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(data)
    }
    //设置搜索value
    const updateSearchParam = (updateParam: IUserFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //编辑餐厅
    const editDetail = useLockFn(async (params: IUserFields) => {
        let { retData } = await userService.update({ ...params })
        if (retData) {
            loadData();
            globalPrompt("message", { text: "用户编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "用户编辑失败", type: "success" })

        }
    })
    return {
        dataSource,
        searchParam,
        initPgCfg,
        pgCfg,
        selectedRowKeys,
        visibleDrawer,
        setDataSource,
        setSearchParam,
        setPgCfg,
        loadData,
        updateSearchParam,
        resetSearchParam,
        setSelectedRowKeys,
        setVisibleDrawer,
        editDetail
    }
})