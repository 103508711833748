import React, { useRef } from "react";
import { YHDrawer } from "@/components/YhDrawer";
import { useRestaurantModel } from "../restaurantModel";
import { YhForm } from "@/components/YhFormBox";
import { IYhForm, IYhFormRef, FormItemTypeEnum } from "@/components/YhFormBox/Types";
import { IRestaurantDetial } from "../restaurantTypes";
import { message } from "antd";
import { getFormValidatorErrText } from "@/utils/utils";
export function DetailDrawer() {
    const {
        visibleDrawer: { visible, mode },
        selectedRow,
        setVisibleDrawer,
        setIVisibleDrawer,
        insertDetail,
        editDetail,
        md5FormVal, setMd5FormVal
    } = useRestaurantModel()
    const formRef = useRef<IYhFormRef>();
    const onCancel = () => {
        setIVisibleDrawer()
        setMd5FormVal([])
    }
    const onSubmit = () => {
        // setIVisibleDrawer()
        formRef.current?.validateFields().then((val: any) => {
            // return console.log(val)
            mode === "edit" ? editDetail(val) : insertDetail(val)
        }).catch(err => {
            message.warning(getFormValidatorErrText(err));
        })
    }
    const onSetFieldsValue = (formValues: { [key: string]: any }, isFormItem: boolean = true) => {
        setMd5FormVal(formValues.image)
    }
    const formConfig: IYhForm<IRestaurantDetial> = {
        formRef,
        labelCol: { span: 6 },
        // formValues: {},
        formValues: mode === "edit" ? selectedRow : {},
        items: [
            {
                type: FormItemTypeEnum.Input,
                span: 24,
                fieldLabel: "餐厅名称",
                fieldName: "name",
                rules: [{ required: true, message: "请输入餐厅名称" }]
            },
            {
                type: FormItemTypeEnum.Input,
                span: 24,
                fieldLabel: "餐厅地址",
                fieldName: "address",
                rules: [{ required: true, message: "请输入餐厅地址" }]
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系人",
                fieldName: "contact",
                span: 24,
                rules: [{ required: true, message: "请输入联系人" }]
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "联系电话",
                fieldName: "phone",
                span: 24,
                rules: [{ required: true, message: "请输入联系电话" }]
            },
            {
                type: FormItemTypeEnum.Input,
                fieldLabel: "餐厅介绍",
                fieldName: "note",
                span: 24,
            },
            {
                type: FormItemTypeEnum.FileImage,
                fieldLabel: "餐厅头像",
                fieldName: "image",
                isOne: true,
                span: 24,
                md5List: md5FormVal,
                onSetFieldsValue
            },
        ]
    }
    return (
        <YHDrawer
            {...{
                title: `餐厅管理${mode === "add" ? '新增' : '编辑'}`,
                visible,
                onCancel,
                okText: "保存",
                onOk: onSubmit
            }}
        >
            <YhForm {...formConfig} />
        </YHDrawer>
    )
}