import React, { useEffect } from "react";
import { Table, Switch, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useLibraryModel } from "../libraryModel";
import { ILibraryDetial } from "../libraryTypes";
import moment, { Moment } from "moment";
import useGlobalModel from "@/model/globalModel";
import { useDictModel } from "@/views/dict/dictModel";
import { getBaseImgLookUrl } from "@/utils/constants";
import { Image } from 'antd';
import { DictCodeEnmu } from "@/types/AppDictEnums";
import { IFoodCategoryOotions } from "@/types/AppEnums";

export function DataTable() {
    const { getSelectedDictOne } = useDictModel()
    const {
        dataSource, selectedRowKeys, pgCfg, setMd5FormVal,
        loadData, setSelectedRowKeys, setSelectedRow, setIVisibleDrawer, removeDetail
    } = useLibraryModel();

    const { getTreeNodeOne } = useDictModel();
    const onEdit = (record: ILibraryDetial) => {
        console.log(record)
        setSelectedRow(record)
        setIVisibleDrawer("edit")
        setMd5FormVal(record.image ? [record.image] : [])
        setSelectedRowKeys([record.id as string])
    }
    const onRemove = (id: string) => {
        removeDetail(id)
    }
    const columns: ColumnsType<ILibraryDetial> = [
        {
            title: '序号',
            width: 80,
            render: (text, record, index) => `${index + 1}`,
            fixed: "left"
        }, {
            title: '菜品名称',
            width: 130,
            dataIndex: 'name',
        }, {
            title: '菜品分类',
            width: 130,
            dataIndex: 'category',
            render: (text) => IFoodCategoryOotions.find(item => item.value === text)?.label
            // render: (text) => text && getSelectedDictOne(DictCodeEnmu.CAIPINFENLEI).filter(item => text.indexOf(item.value) !== -1).map(item => item.label).toString()
        }, {
            title: '菜品标签',
            width: 130,
            dataIndex: 'labelName',
            // render: (text) => text && getSelectedDictOne(DictCodeEnmu.CAIPINBIAOQIAN).filter(item => text.indexOf(item.value) !== -1).map(item => item.label).toString()
        }, {
            title: '餐厅名称',
            width: 130,
            dataIndex: 'canteens',
            render: (text) => text.map((item: any) => item.name).toString()
        }, {
            title: '菜品图片',
            width: 130,
            dataIndex: 'image',
            render: (text: string, record: ILibraryDetial) => <Image width={60} src={getBaseImgLookUrl(text as string)} alt={record.name} />
        }, {
            title: '操作',
            width: 130,
            dataIndex: 'phone',
            render: (text, record) => <div>
                <span className="click-span" onClick={() => onEdit(record)}>编辑</span>
                <span className="click-span" onClick={() => onRemove(record.id as string)}>删除</span>
            </div>
        }
    ];


    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id: string) => {
        // if (selectedRowKeys.length > 0 && selectedRowKeys[0] === id) {
        //     setSelectedRowKeys([]);
        // } else {
        //     setSelectedRowKeys([id as string]);
        // }
    };

    return (
        <Table
            rowKey={record => record.id as string}
            // rowSelection={{
            //     type: "radio",
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: ILibraryDetial[]) => {
            //         setSelectedRowKeys(selectedRowKeys as string[]);
            //     }
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string)
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
