import React from "react";
import { DataBox } from "./DataBox";
// import {YhPageHeader} from "@/components/YhPageHeader";

export function Dict() {
    return (
        <>
            {/* <YhPageHeader title="数据字典管理"/> */}
            <DataBox />
        </>
    )
}