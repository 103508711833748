export enum MdEnableStatusEnum {
    DISABLE,
    ENABLE
}

export enum PartnerTypeEnum {
    CUSTOMER = "PARTNER-TYPE.CUS",
    SUPPLIER = "PARTNER-TYPE.SUP"
}

export enum UserTypeEnum {
    EMPLOYEE = "user-type.employee", // "内部员工"
    MICRO_BOSS = "user-type.micro-boss", // "微老板"
    DRIVER = "user-type.driver", //"司机"
    HEADMAN = "user-type.headman", //"队长"
    BOSS = "user-type.boss", // "老板"
    SUPPLIER = "user-type.supplier", //"供应商"
    CUSTOMER = "user-type.customer", //"客户"
}

export enum TradeTypeEnum {
    SALE = "trade-type.sale", //销售,
    PURCHASE = "trade-type.purchase", //采购
    SALE_RETURN = "trade-type.sale-return", //销售退货
    PURCHASE_RETURN = "trade-type.purchase-return", //采采购退货
    PURCHASE_PLAN = "trade-type.purchase-plan", //采购计划
    SALE_ORDER = "trade-type.sale-order", //销售订单
    APPOINTMENT = "trade-type.appointment", //预约
    MAINTENANCE = "trade-type.maintenance", //维修
    DISPATCH = "trade-type.dispatch", //派工
    SETTLE = "trade-type.settle", //结算
    RETURN_VISIT = "trade-type.return-visit", //回访
    STOCKTAKING = "trade-type.stocktaking", //盘库
    LOSS = "trade-type.loss", //损失
    BILL_DISCOUNT = "trade-type.bill-discount", //账单抹零
}

export enum ReviewStatusEnum {
    INIT,
    REVIEWING,
    REVIEWED,
    REJECTED = 9,
}

export enum AccountStatusEnum {
    INIT,
    DISABLED = 9
}

export enum CreditFlagEnum {
    NON_CREDIT,
    CREDIT
}

export enum AdjustStatusEnum {
    INIT,
    REVIEWING,
    ADJUSTED,
    REJECTED = 8,
    FAIL,
}

export enum RechargeStatusEnum {
    INIT,
    RECHARGED,
    FAIL = 9,
}

export enum TransferStatusEnum {
    INIT,
    RECHARGED,
    FAIL = 9
}

export enum AccountLogTypeEnum {
    OPEN = "OPEN",
    DISABLE = "DISABLE",
    ENABLE = "ENABLE",
    ADJUST = "ADJUST",
    ADJUST_APPROVAL = "ADJUST_APPROVAL",
    RECHARGE = "RECHARGE",
    TRANSFER_IN = "TRANSFER_IN",
    TRANSFER_OUT = "TRANSFER_OUT",
    BALANCE = "BALANCE",
    UPDATE = "UPDATE",
}

export enum BillIoFlagEnum {
    IN = 1,
    OUT
}

export enum BillPayTypeEnum {
    CASH = 1,
    CREDIT_ACCOUNT
}

export enum BillStatusEnum {
    INIT,
    BOOKED,
    BALANCED
}

export enum BillCashStatusEnum {
    INIT,
    CASHED,
    RECHARGED,
    BALANCED,
    FAIL = 9,
}

export enum BillCancelStatusEnum {
    INIT,
    REVIEWING,
    CANCELLED,
    FAIL,
}

export enum BillInvoiceStatusEnum {
    INIT,
    APPLYING,
    PARTIAL,
    INVOICED,
}

export enum BillBalanceStatusEnum {
    INIT,
    BALANCED,
    FAIL = 9,
}

export enum BillLogTypeEnum {
    INSERT = "INSERT",
    APPROVAL = "DISABLE",
    CREDIT_BOOK = "CREDIT_BOOK",
    CASH_BOOK = "CASH_BOOK",
    BALANCE = "BALANCE",
    INVOICE = "INVOICE",
    INVOICE_CANCEL = "INVOICE",
    INVOICE_APPROVAL = "INVOICE_APPROVAL",
    CANCEL = "CANCEL",
    CANCEL_APPROVAL = "CANCEL_APPROVAL",
}


//餐别分类
export enum IMealTypeEnum {
    //早餐
    BREAKFAST = "breakfast",
    //中餐
    LUNCH = "lunch",
    //晚餐
    DINNER = "dinner"
}
export const IMealTypeOptions = [
    { label: "早餐", value: IMealTypeEnum.BREAKFAST },
    { label: "中餐", value: IMealTypeEnum.LUNCH },
    { label: "晚餐", value: IMealTypeEnum.DINNER },
]
export const IMealTypeObj: any = {
    breakfast: "早餐",
    lunch: "中餐",
    dinner: "晚餐",
}
export enum FoodCategory {
    cold = 'cold',
    hot = 'hot',
    staple = 'staple',
    other = 'other',
}
export const IFoodCategoryOotions = [
    { label: "凉菜", value: FoodCategory.cold },
    { label: "热菜", value: FoodCategory.hot },
    { label: "主食", value: FoodCategory.staple },
]