import React from "react";
import "./drawer.less";


type IFooterButtons = {
    text: string,
    style?: React.CSSProperties,
    onClick?: () => void
}

interface IDrawerProps {
    visible: boolean,
    title?: string,
    closable?: boolean,
    mask?: boolean,
    maskClosable?: boolean,
    width?: number,
    children?: React.ReactNode,
    showFooter?: boolean,
    okText?: string
    onOk?: () => void,
    cancelText?: string
    onCancel?: () => void,
    footerButs?: IFooterButtons[]
}

export const YHDrawer = ({
    visible,
    title = "标题",
    onCancel = () => null,
    maskClosable = true,
    mask = false,
    width = 490,
    closable = true,
    children = null,
    showFooter = true,
    okText = "确定",
    onOk,
    cancelText = "取消",
    footerButs = []

}: IDrawerProps): JSX.Element => {

    const handleClose = () => {
        onCancel?.();
    }

    /**
        动态计算抽屉body高度。
        headerH：header标题高度
        footerPadding：footer上下padding高度
        butH：按钮+margin总高度
     */
    const countButLen = (): number => {
        let headerH = 68;
        let footerPadding = 50;
        let butH = 42;
        if (!showFooter) return headerH;
        if (!footerButs) return headerH + footerPadding + butH;
        return (Math.ceil(footerButs.length / 4) + 1) * butH + headerH + footerPadding;
    }

    return <div className="drawer" style={{ width: visible ? "100%" : "0%" }}>
        <div className={["drawer-mask-warp", (mask && visible) ? 'drawer-mask-show' : "drawer-mask-hide"].join(' ')}
            onClick={() => {
                maskClosable && handleClose()
            }}>
        </div>
        <div className={["drawer-main", visible ? "drawer-main-show" : "drawer-main-hide"].join(' ')}
            style={{ width: `${width > 490 ? width : 490}px`, right: visible ? "0" : `-${width}px`, }}>
            <div className="content-warp">
                <div className="drawer-head">
                    <span>{title}</span>
                    {closable && <span className="close-btn" onClick={handleClose}>X</span>}
                </div>
                <div className="drawer-body" style={{ maxHeight: `calc(100% - ${countButLen()}px)` }}>
                    {
                        visible ? children : null
                    }
                </div>
                <div className="drawer-footer" hidden={!showFooter}>
                    {footerButs && footerButs.map((item, index) => (<span key={index} style={item.style} onClick={item.onClick}>{item.text}</span>))}
                    <span onClick={() => {
                        onOk?.();
                    }} className="submit-button">{okText}</span>
                    <span onClick={handleClose}>{cancelText}</span>
                </div>
            </div>
        </div>
    </div>
}