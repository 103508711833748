import {
	IMealDetailFields,
    IMealDetailSearch,
    IMealDetail
} from './mealDetailType';
import { IMealPage } from '@/types/AppTypes';
import { httpRequest } from '@/utils/HttpRequest';
class MealDetailsService {
	// 详情查询
	async page(searchVo: IMealDetailSearch) {
		const apiUrl = '/booking/statistics/userWidthCanteen';
		return await httpRequest.post<IMealPage<IMealDetail>>(apiUrl, searchVo);
	}
	// 详情导出
	async export(searchVo: IMealDetailFields) {
		const apiUrl = '/booking/statistics//userWidthCanteen/export';
		return await httpRequest.postFile<boolean>(apiUrl, searchVo);
	}
}
export const mealDetailsService = new MealDetailsService();
