import React, { useEffect } from "react";
import { Table, Switch, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useUserModel } from "../userModel";
import { IUserDetial } from "../userType";
import moment, { Moment } from "moment";
import useGlobalModel from "@/model/globalModel";
import { useDictModel } from "@/views/dict/dictModel";
export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys,
        editDetail
    } = useUserModel();

    const { getTreeNodeOne } = useDictModel();

    const columns: ColumnsType<IUserDetial> = [
        {
            title: '序号',
            width: 80,
            render: (text, record, index) => `${index + 1}`,
            fixed: "left"
        }, {
            title: '员工工号',
            width: 130,
            dataIndex: 'staffId',
        }, {
            title: '员工账户',
            width: 130,
            dataIndex: 'code',
        }, {
            title: '用户姓名',
            width: 130,
            dataIndex: 'name'
        }, {
            title: '联系电话',
            width: 130,
            dataIndex: 'phoneNumber',
        }, {
            title: '所属机构',
            width: 130,
            dataIndex: 'orgName',
            // }, {
            //     title: '所属门店',
            //     width: 130,
            //     dataIndex: 'shopNameList',
        }, {
            title: '操作',
            width: 130,
            dataIndex: 'phone',
            render: (text, record) => (
                <Space size="middle">
                    {/* checked={record.materialStatus == 0} */}
                    <Switch checked={text === 1} onChange={(checked) => {
                        // onDisabled(record.id as string, checked);
                        editDetail({ ...record, status: checked ? 1 : 0 });
                        // cutDisabled(record,checked)
                    }} />
                </Space>
            )
        }
    ];


    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id: string) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === id) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([id as string]);
        }
    };

    return (
        <Table
            rowKey={record => record.id as string}
            rowSelection={{
                type: "radio",
                selectedRowKeys: selectedRowKeys, //记录选中
                onChange: (selectedRowKeys: React.Key[], selectedRows: IUserDetial[]) => {
                    setSelectedRowKeys(selectedRowKeys as string[]);
                }
            }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string)
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
