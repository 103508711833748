import { httpRequest } from "@/utils/HttpRequest";
export interface IMenuFoods {
    id: number, // 101,
    name: string, // "芹菜炒香肠",
    category: string, // "hot",
    bookingNum: number, // 0
}
export interface IMenuDetail {
    deleteFlag: number,
    createTime: string, // "2021-07-05",
    updateTime: string, // "2021-07-05T1",
    createUser: string, // null,
    updateUser: string, // null,
    id: number, // 1,
    time: string, // "2021-07-05",
    status: number, // 1,
    note: string, // null,
    orderNum: number, // 0,
    canteenId: number, // 2,
    mealId: number, // 4,
    foods: IMenuFoods[]
}
export interface IIBookingStatic {
    bookingTotal: number,
    unfinishedTotal: number,
    cancelTotal: number,
}
export interface IBookingDetail extends IIBookingStatic {
    menuDetail: IMenuDetail
}

class HomeService {
    async bookingDetail(id: string) {
        const apiUrl = `/common/menu/bookingDetail/${id}`;
        return await httpRequest.get<IBookingDetail>(apiUrl);
    }
}
export const homeService = new HomeService()