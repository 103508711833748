import React from "react";
import { DataTable } from "./DataTable";
import { Button } from "antd";
import { YhBox } from "@/components/YhBox";
import { useBreakingModel } from "../breakingModel";
export const DataBox = () => {
    const { listExport } = useBreakingModel();

    const title = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={() => listExport().then()}
        >导出</Button>
    </>
    return (
        <YhBox title={title}>
            <DataTable />
        </YhBox>
    )
}