import React, { useEffect } from "react";
import { Table, Switch, Space, Tooltip } from "antd";
import { ColumnsType } from "antd/es/table";
import { useMealModel, IOrgTimeItem } from "../mealModel";

import { IMealDetial, IMealOrgs } from "../mealTypes";
import moment, { Moment } from "moment";
import useGlobalModel from "@/model/globalModel";
import { useDictModel } from "@/views/dict/dictModel";
import { render } from "react-dom";
import { momentFormat } from "@/utils/utils";
import { IMealTypeEnum, IMealTypeOptions } from "@/types/AppEnums";
import { mealService } from "../mealService"
export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        mealTimeList,
        loadData, setSelectedRowKeys,
        setIVisibleDrawer,
        setEditFormVal,
        setMealTypeList,
        setMealTimeList,
        setOrderInCalel,
        removeDetail,
        setOrgTimeList
    } = useMealModel();

    const { getTreeNodeOne } = useDictModel();
    const onEdit = async (record: IMealDetial) => {
        // console.log(record)
        let { retData } = await mealService.one(record.id as string)
        if (retData) {
            let { mealOrgs, mealType, startTimeBook, endTimeBook, startTimeBusiness, cancelMealTime, endTimeBusiness, ...params } = retData as { cancelMealTime: string, mealType: string, startTimeBook: string, endTimeBook: string, startTimeBusiness: string, endTimeBusiness: string, mealOrgs: IMealOrgs[] }
            setEditFormVal({ ...params,mealType, orgType: mealOrgs.map(item => item.orgCode) })//
            setMealTypeList([mealType as IMealTypeEnum])
            setSelectedRowKeys([record.id as string])
            setOrderInCalel({ startTimeBook, endTimeBook, cancelMealTime })
            setMealTimeList({ ...mealTimeList, [mealType]: { startTimeBusiness, endTimeBusiness } })

            let newMealOrgs: IOrgTimeItem[] = mealOrgs.map((item) => {
                let { orgCode, orgName, startTime, endTime } = item as { orgCode: string, orgName: string, startTime: string, endTime: string }
                return { orgCode, orgName, startTime, endTime }
            })
            setOrgTimeList(newMealOrgs)
            setIVisibleDrawer("edit")

        }

        // let { mealType, startTimeBook, endTimeBook, startTimeBusiness, cancelMealTime, endTimeBusiness, ...params } = record as { cancelMealTime: string, mealType: string, startTimeBook: string, endTimeBook: string, startTimeBusiness: string, endTimeBusiness: string }
        // setEditFormVal(record)
        // setIVisibleDrawer("edit")
        // setMealTypeList([mealType as IMealTypeEnum])
        // setSelectedRowKeys([record.id as string])
        // setOrderInCalel({ startTimeBook, endTimeBook, cancelMealTime })
        // setMealTimeList({ ...mealTimeList, [mealType]: { startTimeBusiness, endTimeBusiness } })
    }
    const onRemove = (id: string) => {
        removeDetail(id)
    }
    const columns: ColumnsType<IMealDetial> = [
        {
            title: '序号',
            width: 80,
            render: (text, record, index) => `${index + 1}`,
            fixed: "left"
        }, {
            title: '餐厅名称',
            width: 130,
            dataIndex: 'canteen',
            render: (text) => text?.name
        }, {
            title: '餐别名称',
            width: 130,
            dataIndex: 'mealType',
            render: (text) => IMealTypeOptions.find(item => item.value === text)?.label

        }, {
            title: '营业时间',
            width: 130,
            dataIndex: 'startTimeBusiness',
            render: (text, record) => `${(record.startTimeBusiness as string)}——${(record.endTimeBusiness as string)}`
        }, {
            title: '操作',
            width: 130,
            render: (text, record) => <div>
                <span className="click-span" onClick={() => onEdit(record)}>编辑</span>
                <span className="click-span" onClick={() => onRemove(record.id as string)}>删除</span>
            </div>
        }
    ];


    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id: string) => {
        // if (selectedRowKeys.length > 0 && selectedRowKeys[0] === id) {
        //     setSelectedRowKeys([]);
        // } else {
        //     setSelectedRowKeys([id as string]);
        // }
    };

    return (
        <Table
            rowKey={record => record.id as string}
            // rowSelection={{
            //     type: "radio",
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: IMealDetial[]) => {
            //         setSelectedRowKeys(selectedRowKeys as string[]);
            //     }
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            // onRow={record => ({
            //     onClick: () => onRow(record.id as string)
            // })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
