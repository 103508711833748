import React, { useState, useEffect } from "react";
import { Button, Upload, Calendar, Checkbox } from "antd";
import { YhBox } from "@/components/YhBox";
import { useReleaseModel } from "../releaseModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { importPropsConfig } from "@/utils/importFile";
import { AuthButton } from "@/components/auth/AuthButton";
import useGlobalModel from "@/model/globalModel";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import loginBg from "@/assets/lzjd.jpg"
import { IFoodCategoryOotions } from "@/types/AppEnums";
import { getBaseImgLookUrl } from "@/utils/constants";
import { useDictModel } from "@/views/dict/dictModel";
import { DictCodeEnmu } from "@/types/AppDictEnums";
import { indexOf, debounce } from "lodash";
const mealList = ["凉菜", "热菜", "主食"]
const food = [
    { src: loginBg, name: "红烧肉", status: true, total: 20, texture: "香" },
    { src: loginBg, name: "肘子", status: true, total: 20, texture: "香香" },
    { src: loginBg, name: "糖醋里脊", status: true, total: 20, texture: "真香" },
]
export const MealBox = () => {
    const { getSelectedDictOne } = useDictModel()

    const { visibleDrawer, initPgCfg,
        dayMeal,
        mealList,
        dayMealList,
        mealType,
        mealSearchParam,
        setMealSearchParam,
        setMealList,
        setVisibleDrawer,
        loadLibrary,
        setMealType,
        setDayMealList,
        insertMenu,
        updateMenu
    } = useReleaseModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel();
    const [foodList, setFoodList] = useState<any[]>(food)
    useEffect(() => {
        loadLibrary()
    }, [])
    const onAdd = () => {
        setVisibleDrawer(true)
    }
    const onMealType = (item: any, index: number) => {
        setMealSearchParam({ ...mealSearchParam, category: item.value })
        // setMealType(item.value)
        loadLibrary(initPgCfg, { ...mealSearchParam, category: item.value })
        // loadLibrary()
    }
    // const onFood = (item: any, index: number) => {
    //     let arr = [];
    //     let newFoodList = foodList.map((foodItem, foodIndex) => {
    //         if (foodIndex === index) {
    //             return {
    //                 ...foodItem,
    //                 status: !foodItem.status
    //             }
    //         } else {
    //             return foodItem
    //         }
    //     })
    //     console.log(newFoodList)
    //     setFoodList(newFoodList)
    // }
    const onFood = (item: any, index: number) => {
        let findIndex = dayMealList.indexOf(item.id as number)
        let newDayMealList = [...dayMealList]
        if (findIndex === -1) {
            newDayMealList.push(item.id)
            setDayMealList(newDayMealList)
        } else {
            newDayMealList.splice(findIndex, 1);
            setDayMealList(newDayMealList)
        }
    }
    const onLabelChina = (text: string) => {
        return getSelectedDictOne(DictCodeEnmu.CAIPINBIAOQIAN).filter(item => text.indexOf(item.value as string) !== -1).map(item => item.label).toString()
    }
    const onRelease = () => {
        dayMeal?.foods ? updateMenu() : insertMenu()
    }
    return (
        <div className="right">
            <YhBox
                title={""}
                style={{
                    width: "286px",
                    height: "690px",
                    marginRight: "20px",
                }}
            >
                <div className="release">
                    <div className="meal">
                        {IFoodCategoryOotions.map((item, index) => (
                            <p key={index} className={`meal-type ${item.value === mealSearchParam.category ? 'meal-active' : ''}`} onClick={() => onMealType(item, index)}>{item.label}</p>
                        ))}
                    </div>
                    <Button
                        className="mr-5"
                        type="link"
                        shape="round"
                        // onClick={onRelease}
                        onClick={debounce(() => { onRelease() }, 900)}
                    >{dayMeal?.foods ? '菜品修改' : '菜品发布'}</Button>
                </div>
            </YhBox>
            <YhBox
                title={""}
                style={{
                    width: "555px",
                    height: "690px",
                    overflowY: "auto"
                }}
            >
                <div className="food">
                    {mealList.filter(fliterItem => dayMealList.indexOf(fliterItem.id as number) !== -1)
                        .map((item, index) => {
                            return (
                                <div key={index} className="food-item" onClick={() => onFood(item, index)}>
                                    <div className="img">
                                        <img className="img-detail" src={getBaseImgLookUrl(item.image as string)} alt={item.name} />
                                        {/* <Checkbox className="img-check" checked={dayMealList.indexOf(item.id as number) !== -1 ? true : false} /> */}
                                        <Checkbox className="img-check" checked={true} />
                                    </div>
                                    <div className="message">
                                        <span className="name">{item.name}</span>
                                        <p className="other">
                                            <span>口感：{item.labelName}</span>
                                            <span>累计：{item.menuNumber}</span>
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {mealList.filter(fliterItem => dayMealList.indexOf(fliterItem.id as number) === -1)
                        .map((item, index) => {
                            return (
                                <div key={index} className="food-item" onClick={() => onFood(item, index)}>
                                    <div className="img">
                                        <img className="img-detail" src={getBaseImgLookUrl(item.image as string)} alt={item.name} />
                                        {/* <Checkbox className="img-check" checked={dayMealList.indexOf(item.id as number) !== -1 ? true : false} /> */}
                                        <Checkbox className="img-check" checked={false} />

                                    </div>
                                    <div className="message">
                                        <span className="name">{item.name}</span>
                                        <p className="other">
                                            <span>口感：{item.labelName}</span>
                                            <span>累计：{item.orderNum}</span>
                                        </p>
                                    </div>
                                </div>
                            )
                        })
                    }
                    {/* {mealList.filter((item, index) => {
                        if (dayMealList.indexOf(item.id as number) === -1) {    
                            return (
                                <div key={index} className="food-item" onClick={() => onFood(item, index)}>
                                    <div className="img">
                                        <img className="img-detail" src={getBaseImgLookUrl(item.image as string)} alt={item.name} />
                                        <Checkbox className="img-check" checked={dayMealList.indexOf(item.id as number) !== -1 ? true : false} />
                                    </div>
                                    <div className="message">
                                        <span className="name">{item.name}</span>
                                        <p className="other">
                                            <span>口感：{item.labelName && onLabelChina(item.labelName)}</span>
                                            <span>累计：{item.orderNum}</span>
                                        </p>
                                    </div>
                                </div>
                            )
                        // }

                    })} */}
                    {/* {mealList.filter((item, index) => {
                        return dayMealList.indexOf(item.id as number) === -1 && (
                            <div key={index} className="food-item" onClick={() => onFood(item, index)}>
                                <div className="img">
                                    <img className="img-detail" src={getBaseImgLookUrl(item.image as string)} alt={item.name} />
                                    <Checkbox className="img-check" checked={dayMealList.indexOf(item.id as number) !== -1 ? true : false} />
                                </div>
                                <div className="message">
                                    <span className="name">{item.name}</span>
                                    <p className="other">
                                        <span>口感：{item.labelName && onLabelChina(item.labelName)}</span>
                                        <span>累计：{item.orderNum}</span>
                                    </p>
                                </div>
                            </div>
                        )
                    })} */}
                </div>
            </YhBox>
        </div>
    )
}