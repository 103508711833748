import {httpRequest} from "@/utils/HttpRequest";
import {IOrgs} from "@/views/org/orgTypes";


class OrgService {
    async tree() {
        const apiUrl = `/common/org/tree`;
        return await httpRequest.post<IOrgs[]>(apiUrl);
    }
}

export const orgService = new OrgService()
