import React from "react";
import { useMealModel } from "../mealModel";

import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import useGlobalModel from "@/model/globalModel";
import { IMealTypeOptions } from "@/types/AppEnums";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useMealModel();
    const searchFields = [
        {
            type: SearchTypeEnum.Input,
            span: 4,
            placeholder: "餐厅名称",
            fieldName: "canteenName",
            size: "small",
        },
        {
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "餐别",
            fieldName: "mealType",
            size: "small",
            options: IMealTypeOptions,
        },
    ]
    return (
        <YhPageHeader
            title="餐别管理"
            triggerSearch={true}
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    );
}