import React from "react";
import { Switch, Route } from "react-router-dom";
import { Home } from "../views/home/Home";
import { Restaurant } from "../views/restaurant/view";
import { Meal } from "../views/meal/view";
import { Library } from "../views/food/library/view";
import { Release } from "../views/food/release/view";
import { User } from "../views/user/view";
// import { MealReport } from "../views/mealReport/view";
import { Dict } from "../views/dict/view";
import { statisticsMealReport } from "../views/statistics/mealReport/view";
import { statisticsBreaking } from "../views/statistics/breaking/view";
import {statisticsMealDetail} from "../views/statistics/mealDetails/view"

import Error404 from "../views/404";

function AppRoute() {
    return (
        <Switch>
            <Route exact={true} path={"/"} key="1" component={Home} />
            <Route exact={true} path={"/restaurant"} key="2" component={Restaurant} />
            <Route exact={true} path={"/meal"} key="3" component={Meal} />
            <Route exact={true} path={"/food/library"} key="4-1" component={Library} />
            <Route exact={true} path={"/food/release"} key="4-2" component={Release} />
            <Route exact={true} path={"/user"} key="5" component={User} />
            <Route exact={true} path={"/statistics/mealReport"} key="6-1" component={statisticsMealReport} />
            <Route exact={true} path={"/statistics/breaking"} key="6-2" component={statisticsBreaking} />
            <Route exact={true} path={"/statistics/mealDetails"} key="6-3" component={statisticsMealDetail} />
            <Route exact={true} path={"/dict"} key="7" component={Dict} />
            <Route exact={true} path={"/*"} key="404" component={Error404} />
        </Switch>
    )
}

export default AppRoute;