import React, { useState, useEffect } from "react";
import { createModel } from "hox";
import useStorageState from "@/hooks/useStorageState";
import { globalPrompt } from "@/components/message";
import { dictService, IMEALDictDetail, IMEALDictSearch } from "./dictService";
import { forFind } from "@/utils/utils";
import { DictCodeEnmu } from "@/types/AppDictEnums";
import { ISelectOptions } from "@/types/AppTypes";

type dictObj = {
    [key: string]: any
}

export type ITreeSelectData = {
    title: string,
    value: string,
    selectable?: boolean,
    children: ITreeSelectData[]
}

function listChangeTree(list: IMEALDictDetail[]) {
    let menuObj: dictObj = {}
    list.forEach(item => {
        item.children = []
        menuObj[item.code] = item
    })
    return list.filter(item => {
        if (item.pcode !== null && item.pcode !== "") {
            menuObj[item.pcode]?.children.push(item)
            return false
        }
        return true
    })
}

export const useDictModel = createModel(function () {

    const [treeSource, setTreeSource, clearTreeSource] = useStorageState<IMEALDictDetail[]>("dictTree", []);  //字典数据源
    // const [originDictList, setOriginDictList, clearOriginDictList] = useStorageState<IMEALDictDetail[]>("dictList", []);  //字典数据源
    const [selectedNodes, setSelectedNodes] = useState<IMEALDictDetail[]>([]);   //树选中的行信息
    const [mode, setMode] = useState<"add" | "nextAdd" | "edit" | "root" | "">("");  //模式
    const [formVal, setFormVal] = useState<IMEALDictDetail>(selectedNodes[0]);  //表单缓存

    //实时缓存
    const onChangeFormVal = (changedValues: { [key: string]: any }) => {
        setFormVal({ ...formVal, ...changedValues });
    }

    //字典初始
    const initDictFormFields = {
        key: "888",
        id: "",// "",
        code: "",// "",
        name: "",// "",
        value: "",// "",
        pcode: "",// "",
        note: "",// "",
        order_num: "",// "",
        status: "",// "",
        children: []
    }

    useEffect(() => {
        if (mode === "add") {
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].pcode })
        } else if (mode === "nextAdd") {
            setFormVal({ ...selectedNodes[0], ...initDictFormFields, pcode: selectedNodes[0].code })
        } else if (mode === "root") {
            setSelectedNodes([initDictFormFields])
            setFormVal(initDictFormFields)
        }
    }, [mode])


    function setTreePcode(array: IMEALDictDetail[], pcode: string) {
        array.forEach(item => {
            //有权限就设置为展示
            item.pcode = pcode;
            item.key = item.id;
            if (item.children && item.children.length > 0)
                setTreePcode(item.children, item.code);
        })
    }


    //字典列表请求
    const loadData = async () => {
        let data: IMEALDictSearch = {

        }
        let { retData } = await dictService.tree(data);
        setTreePcode(retData, "");
        // console.log(retData)
        setTreeSource(retData);
    }

    //表单提交
    const onSubmit = async () => {
        console.log(formVal)
        let { code, pcode, note, name } = formVal;
        let data = {
            code, pcode, note, name,
            status:1
            // "order_num": "1",
            // "status": "1"
        }
        if (!data.code || !data.name) {
            globalPrompt("message", { text: "请检查必填项", type: "warning" });
            return;
        }
        if (mode === "add" || mode === "nextAdd" || mode === "root") {
            let { retData } = await dictService.insert(data);
            retData && globalPrompt("message", { type: "success", text: "字典增加成功" });
            loadData();
            setSelectedNodes([]);
        } else if (mode === "edit") {
            let { retData } = await dictService.update({ ...data, id: formVal.id });
            retData && globalPrompt("message", { type: "success", text: "字典编辑成功" });
            loadData();
            setSelectedNodes([]);
        }
    }

    //删除一条数据
    const removeOneDict = async (code: string) => {
        let { retData } = await dictService.remove(code);
        retData && globalPrompt("message", { type: "success", text: "字典删除成功" });
        loadData();
        setSelectedNodes([]);
    }

    //根据code返回 select框的options 格式；
    const getSelectedDictOne = (code: DictCodeEnmu): ISelectOptions[] => {
        let findTree: IMEALDictDetail | undefined = forFind(treeSource, code, "code");
        return findTree ? findTree.children.map((item: any) => ({ label: item.name, value: item.code, type: item.value ?? "" })) : []
    }

    // //根据code 返回对应的node节点名称
    // const getTreeNodeName = (code: string): string => {
    //     let obj: dictObj = {};
    //     let findNode: IMEALDictDetail | undefined = originDictList.find(item => item.code === code);
    //     return findNode ? findNode["name"] : "";
    // }

    //根据code 返回对应树节点
    const getTreeNodeOne = (code: DictCodeEnmu): IMEALDictDetail[] => {
        let findTree: IMEALDictDetail | undefined = forFind(treeSource, code, "code");
        return findTree ? [findTree] : [];
    }


    const mapTree = (org: IMEALDictDetail, selectable: boolean): ITreeSelectData => {
        const haveChildren = Array.isArray(org.children) && org.children.length > 0;
        return {
            title: org.name,
            value: org.code,
            selectable: selectable ? true : haveChildren ? false : true,
            //判断它是否存在子集，若果存在就进行再次进行遍历操作，知道不存在子集便对其他的元素进行操作
            children: haveChildren ? org.children.map(i => mapTree(i, selectable)) : []
        };
    }

    /**
     * 
     * @param code 根据code 返回treeSelect 数据格式
     * @param selectable 有子节点的父节点不能选中  (默认只能选中子节点)
     */
    const getTreeSelectOne = (code: DictCodeEnmu, selectable: boolean = false): ITreeSelectData[] => {
        let findTree: IMEALDictDetail | undefined = forFind(treeSource, code, "code");
        let resultTree: ITreeSelectData[] = findTree ? mapTree(findTree, selectable).children : [];
        return resultTree;
    }

    return {
        /***field***/
        treeSource,
        selectedNodes,
        mode,
        formVal,
        /***method***/
        setTreeSource,
        setSelectedNodes,
        setMode,
        setFormVal,
        onChangeFormVal,
        loadData,
        onSubmit,
        removeOneDict,
        getSelectedDictOne,
        // getTreeNodeName,
        getTreeNodeOne,
        getTreeSelectOne
    }
});
