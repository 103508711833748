import { createModel } from "hox";
import useStorageState from "../hooks/useStorageState";
import { IPgProps, ISelectOptions } from "@/types/AppTypes";
import { PaginationProps } from "antd/es/pagination";
import { useState } from "react";
import { ISelectOption } from "@/components/YhFormBox/Types";
import { IRestaurantDetial } from "@/views/restaurant/restaurantTypes";
import { restaurantService } from "@/views/restaurant/restaurantServices";

export function transPgToPagination(pg: IPgProps): PaginationProps {
    return {
        total: pg.total,
        pageSize: pg.size ?? pg.pageSize,
        current: pg.current ?? pg.pageNum,
    }
}

export function fn(): Promise<ISelectOption[]> {
    let arr: ISelectOption[] = [];
    return Promise.resolve(arr)
}

export type RoleType = {
    id: string,
    name: String,
};
export type UserType = {
    id: string,
    userName: string,
    password: String,
    token: string,
    shopId: string,
    shopName: string,
    loginType?: "account" | "token"
    [key: string]: any
}

export type LoadingType = { loadingVisible: boolean, txt: string };

type GlobalModelType = {
    user: UserType,
    setUser: (e: UserType) => void,
    resetUser: () => void,
    toggleMenu: () => void,
    cm: CmType,
    resetCm: () => void,
    loadingInfo: LoadingType,
    setLoadingInfo: (origin: LoadingType) => void,

    resourceAuth: string[],
    setResourceAuth: (origin: string[]) => void
    dataRestaurantSource:IRestaurantDetial[],
    menuAuth: string[],
    setMenuAuth: (origin: string[]) => void,
    [key: string]: any
};


const initUserState = {
    id: "",
    userName: "",
    password: "",
    token: "",
    shopId: "",
    shopName: "",
}

type CmType = {
    collapsed: boolean
}
const initCmState = {
    collapsed: false
}
//返回列表韦options的格式
export const transOptions = (list: any[]) => {
    return list.length != 0 ? list.map(item => ({ label: item.name, value: item.id })) : []
}
export const getFindItem = (id: string, list: any[]) => {
    return list.find(item => item.id === id)
}
function useModel(): GlobalModelType {
    //用户所有菜单权限
    const [menuAuth, setMenuAuth] = useStorageState<string[]>("user.menus", []);
    //用户所有资源权限
    const [resourceAuth, setResourceAuth] = useStorageState<string[]>("user.resource", []);
    //用户所有信息
    const [user, setUser, resetUser] = useStorageState<UserType>('user', initUserState);
    const [cm, setCm, resetCm] = useStorageState<CmType>('cm', initCmState);
    //全局loading
    const [loadingInfo, setLoadingInfo] = useState<LoadingType>({ loadingVisible: false, txt: "" });
    //所有餐厅
    const [dataRestaurantSource, setDataRestaurantSource] = useStorageState<IRestaurantDetial[]>('restauranAll', []);
    //选中餐厅
    const [restaurantSourceOne, setRestaurantSourceOne] = useStorageState<IRestaurantDetial>('restauranOne', {});

    const toggleMenu = () => {
        setCm({
            ...cm,
            collapsed: !cm.collapsed
        })
    }
    //所有餐厅
    const loadRestaurantAll = async () => {
        const searchParams = {
            page: {
                pageSize: 10000,
                pageNum: 1,
            }
        }
        let { retData } = await restaurantService.page(searchParams);
        let { data, } = retData;
        setDataRestaurantSource(data)
    }

    return {
        user, setUser, resetUser,
        cm, toggleMenu, resetCm,
        loadingInfo, setLoadingInfo,
        resourceAuth, setResourceAuth,
        menuAuth, setMenuAuth,
        dataRestaurantSource, setDataRestaurantSource,
        loadRestaurantAll,
        restaurantSourceOne, setRestaurantSourceOne

    };
}

export const useGlobalModel = createModel(useModel)
export default useGlobalModel;
