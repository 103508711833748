import { createModel } from "hox";
import { useState, useEffect } from "react";
import { IRestaurantSearch, IRestaurantDetial, IRestaurantFields } from "./restaurantTypes"
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { restaurantService } from "./restaurantServices";
import { ISearchPage } from "@/types/AppTypes";
import useGlobalModel, { transPgToPagination } from "@/model/globalModel";
import { useLockFn } from "ahooks";
import { globalPrompt } from "@/components/message";
import useStorageState from "@/hooks/useStorageState";

interface IRestaurantType {
    visible: boolean,
    mode: "add" | "edit"
}
export const useRestaurantModel = createModel(function () {
    const { loadRestaurantAll } = useGlobalModel()
    //列表数据源
    const [dataSource, setDataSource] = useState<IRestaurantDetial[]>([]);

    //查询条件
    const [searchParam, setSearchParam] = useState<IRestaurantFields>({});
    //抽屉框
    const initShopDrawer: IRestaurantType = { visible: false, mode: "add" }
    const [visibleDrawer, setVisibleDrawer] = useState<IRestaurantType>(initShopDrawer);
    const setIVisibleDrawer = (str?: "add" | "edit") => setVisibleDrawer(str ? { visible: true, mode: str } : initShopDrawer);
    // const 
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    const [selectedRow, setSelectedRow] = useState<IRestaurantDetial>({});

    const [addFormVal, setAddFormVal] = useState<IRestaurantDetial>({});
    const [editFormVal, setEditFormVal] = useState<IRestaurantDetial>({});

    const [md5FormVal, setMd5FormVal] = useState<string[]>([]) //图片md5值
    const loadData = async (page?: ISearchPage, searchValue: IRestaurantFields = searchParam) => {
        const searchParams = {
            ...searchValue,
            page: {
                pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await restaurantService.page(searchParams);
        let { data, ...pg } = retData;
        setPgCfg(transPgToPagination(pg));
        setDataSource(data)
        loadRestaurantAll()

    }
    //设置搜索value
    const updateSearchParam = (updateParam: IRestaurantFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData(initPgCfg, {});
    }
    //新增餐厅
    const insertDetail = useLockFn(async (params: IRestaurantFields) => {
        let { retData } = await restaurantService.insert({ ...params, image: md5FormVal.toString() })
        if (retData) {
            loadData();
            setIVisibleDrawer();
            globalPrompt("message", { text: "餐厅新增成功", type: "success" })
        } else {
            globalPrompt("message", { text: "餐厅新增失败", type: "success" })

        }
    })
    //编辑餐厅
    const editDetail = useLockFn(async (params: IRestaurantFields) => {
        let { retData } = await restaurantService.update({ ...params, id: selectedRowKeys[0], image: md5FormVal.toString() })
        if (retData) {
            loadData();
            setIVisibleDrawer();
            globalPrompt("message", { text: "餐厅编辑成功", type: "success" })
        } else {
            globalPrompt("message", { text: "餐厅编辑失败", type: "success" })

        }
    })
    //删除餐厅
    const removeDetail = useLockFn(async (id: string) => {
        let { retData } = await restaurantService.remove(id)
        if (retData) {
            loadData();
            globalPrompt("message", { text: "餐厅删除成功", type: "success" })
        } else {
            globalPrompt("message", { text: "餐厅删除失败", type: "success" })

        }
    })
    return {
        dataSource,
        searchParam,
        initPgCfg,
        pgCfg,
        selectedRowKeys,
        visibleDrawer,
        setDataSource,
        setSearchParam,
        setPgCfg,
        loadData,
        updateSearchParam,
        resetSearchParam,
        setSelectedRowKeys,
        setVisibleDrawer,
        setIVisibleDrawer,
        selectedRow,
        setSelectedRow,
        insertDetail,
        editDetail,
        removeDetail,
        md5FormVal, setMd5FormVal,
    }
})