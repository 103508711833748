import React from "react";
import { ISearchField, ISearchProp } from "../Types";
import { Select } from "antd";

type PropType = ISearchProp & ISearchField;
export const SearchSelect = (props: PropType) => {
    return <Select
        size={props.size}
        allowClear
        mode={props.mode}
        className="ant-select-round"
        value={props.searchValues?.[props.fieldName] as string} 
        placeholder={props.placeholder}
        onChange={(value) => {
            props.onChange?.({ [props.fieldName]: value })
            if (props.triggerSearch) props.handleSearch?.({ [props.fieldName]: value });
        }}
        disabled={props.disabled}
        options={props.options ?? []}
    />
}