import React, { ChangeEvent, useState, useEffect } from "react";
import logoImg from "../../assets/images/logo1.png";
import accountImg from "../../assets/images/account.png";
import passwordImg from "../../assets/images/password.png";
import { message, Modal } from "antd";
import useGlobalModel from "../../model/globalModel";
import { useHistory } from "react-router-dom";
import "./Login.scss";
import { getToken, setToken, defaultMdCmParam, CLIENT_APP_CODE } from "../../utils/constants";
import { loginService } from "./LoginService";
import { loginServiceAuth2, Auth2UserInfo, Auth2MenuDetail } from "./LoginServiceAuth2";
import { globalPrompt } from "@/components/message";
import { SmileTwoTone, ToolOutlined } from "@ant-design/icons";
import { useLockFn } from "ahooks";
import { debounce } from "lodash";

interface IgetIpType {
    cid: string,
    cip: string,
    cname: string,
}
declare const window: Window & { returnCitySN?: IgetIpType };

export const Login = () => {
    const history = useHistory()
    const { user, setUser, setResourceAuth, setMenuAuth } = useGlobalModel();

    const [state, setstate] = useState({
        userAccount: '',
        userPassword: '',
        token: ''
    });

    const [checkPwd, setCheckPwd] = useState<boolean>(() => {
        let userAccount = window.localStorage.getItem('userAccount');
        let userPassword = window.localStorage.getItem('userPassword');
        if (userAccount || userPassword) {
            return true
        }
        return false
    });

    const [loading, setLoading] = useState<boolean>(false);

    const onAccountChange = (e: ChangeEvent<HTMLInputElement>) => {
        setstate({ ...state, userAccount: e.target.value });
        checkPwd && window.localStorage.setItem('userAccount', e.target.value);

    }
    const onPasswordChange = (e: ChangeEvent<HTMLInputElement>) => {
        setstate({ ...state, userPassword: e.target.value })
        checkPwd && window.localStorage.setItem('userPassword', e.target.value);
    }

    const [IP, setIP] = useState<string>("");

    //回显记住账号密码
    useEffect(() => {
        let userAccount = window.localStorage.getItem('userAccount');
        let userPassword = window.localStorage.getItem('userPassword');

        if (userAccount || userPassword) {
            setstate({
                ...state,
                userAccount: userAccount ?? "",
                userPassword: userPassword ?? ""
            });
        }

        setIP(window.returnCitySN?.cip ?? "");  //设置ip
    }, []);

    useEffect(() => {
        user.token && history.push('/')
    }, [user.token])


    const beforeLogin = async (token: string) => {
        let menuArr: string[] = [];
        function findMenuAll(array: Auth2MenuDetail[]) {
            array.forEach(item => {
                item.linkUrl && menuArr.push(item.linkUrl);
                if (item.children && item.children.length > 0)
                    findMenuAll(item.children);
            })
        }

        try {
            let { retData, retMsg } = await loginServiceAuth2.VerifyInfo({ sourceApp: defaultMdCmParam.sourceApp, token });
            let { menus, resources, roles, shops, ...userInfo } = retData ?? {};

            setResourceAuth(resources ?? []);  //设置资源列表

            //设置用户菜单所有权限列表,(递归查询到所有要展示得菜单地址。)
            findMenuAll(menus?.[CLIENT_APP_CODE] ?? []);
            setMenuAuth(menuArr);


            setUser({ ...user, ...userInfo, token, shopId: "", shopName: "", loginType: 'account' });
            loginServiceAuth2.recordAppLoginInfo(CLIENT_APP_CODE, userInfo.userCode);
            globalPrompt("notification", {
                type: "success", title: "登录成功",
                duration: 3,
                text: <p className="bold"><span className="block"><SmileTwoTone /> {`您好!${userInfo.userName ?? ""}`}</span>欢迎使用云豪报餐管理系统</p>
            });

        } catch (error) { setLoading(false) }
    }

    // 登录
    const doLogin = debounce(async () => {
        try {
            if (!state.userAccount) {
                message.warning("请输入账号");
                setLoading(false)
                return;
            }
            if (!state.userPassword) {
                setLoading(false);
                message.warning("请输入密码");
                return
            }
            // debugger;
            let { retData, retMsg } = await loginServiceAuth2.Login({
                userCode: state.userAccount,
                userPassword: state.userPassword,
                token: state.token,
                // sourceApp: defaultMdCmParam.branch,
            }, { headers: { IP } })
            if (retData) {
                setToken(retData)
                beforeLogin(retData);
            } else {
                message.error("登录失败：" + retMsg);
                setLoading(false)
            }
        } catch (error) { setLoading(false) }
    }, 800);


    //记住密码
    const checkout = (e: any) => {
        let checked = e.target.checked;
        if (checked) {
            setCheckPwd(true);
            window.localStorage.setItem('userAccount', state.userAccount);
            window.localStorage.setItem('userPassword', state.userPassword);
        } else {
            setCheckPwd(false);
            window.localStorage.removeItem('userAccount');
            window.localStorage.removeItem('userPassword');
        }
    };

    return (
        <div className="container-login">
            <div className="login-warp">
                <div className="bg1">
                    <img src={logoImg} alt="" className="login-logo" />
                    <p>让天下商用车用户全程无忧</p>
                    <p>让我们的员工及合作伙伴都能过上好日子</p>
                </div>

                <div className="input-box">
                    <div className="input-title">
                        <span>用户登录</span>
                    </div>
                    <p className="account">
                        <span><img src={accountImg} alt="" /></span>
                        <input type="text" placeholder='请输入账号' value={state.userAccount} onChange={onAccountChange}
                            onKeyUp={(e) => {
                                if (e.keyCode === 13) {
                                    setLoading(true);
                                    !loading && doLogin();
                                }
                            }} />
                    </p>
                    <p className="password">
                        <span><img src={passwordImg} alt="" /></span>
                        <input type='password' placeholder='请输入密码' value={state.userPassword} onChange={onPasswordChange}
                            onKeyUp={(e) => {
                                if (e.keyCode === 13) {
                                    setLoading(true);
                                    !loading && doLogin();
                                }
                            }} />
                    </p>
                    <div className="login-input-three">
                        <span><input type="checkbox" defaultChecked={checkPwd} onClick={checkout} /> 记住密码</span>
                    </div>
                    <p className="login-but" onClick={() => {
                        setLoading(true);
                        doLogin();
                    }}>登录</p>

                    {loading && <div className="caseMarronFonce">
                        <div className="spinner"></div>
                        <div className="spinner-text">登录中。。。</div>
                    </div>}
                </div>
            </div>
        </div>
    )
}

export default Login;