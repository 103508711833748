import { createModel } from "hox";
import { useState, useEffect } from "react";
import { IReleaseFields, IReleaseDetial, IReleaseSearch, IFoods } from "./releaseTypes"
import { TablePaginationConfig } from "antd/es/table/interface";
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from "@/utils/constants";
import { releaseService } from "./releaseService";
import { ISearchPage } from "@/types/AppTypes";
import { FoodCategory, IMealTypeEnum } from "@/types/AppEnums";
import { ILibraryDetial, ILibraryFields, ILibrarySearch } from "@/views/food/library/libraryTypes"
import { libraryService } from "@/views/food/library/libraryService"
import { IMealFields } from "@/views/meal/mealTypes";
import moment from "moment"
import { globalPrompt } from "@/components/message";
import { useLockFn } from "ahooks";
export interface IHomeInfo {
    "canteenId"?: string,
    "mealId": string,
}
export interface ITimeStartInEnd {
    "startTime": string,// "2021-05-01",
    "endTime": string,// "2021-07-01"
}
export interface IMealSearchParam {
    name: string,
    category: FoodCategory
}
export const useReleaseModel = createModel(function () {
    // 餐厅id
    const [homeInfo, setHomeInfo] = useState<IHomeInfo | null>(null)
    //餐别分类
    const [meal, setMeal] = useState<IMealTypeEnum>(IMealTypeEnum.LUNCH)
    //餐别分类数组
    const [mealIdList, setMealIdList] = useState<IMealFields[]>([])

    //列表数据源
    const [dataSource, setDataSource] = useState<IReleaseDetial[]>([]);
    //查询条件
    const [searchParam, setSearchParam] = useState<IReleaseFields>({});
    //抽屉框
    const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
    // const 
    //分页参数
    const initPgCfg: TablePaginationConfig = { pageSize: DEFAULT_PAGE_SIZE, current: DEFAULT_FIRST_PAGE_NUM }
    const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //表格多选id值
    const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
    //菜品分类当前下表
    const [mealType, setMealType] = useState<FoodCategory>(FoodCategory.cold);
    //菜品列表
    const [mealList, setMealList] = useState<ILibraryDetial[]>([]);
    const initMealSearchParam = {
        name: "",
        category: FoodCategory.cold
    }
    const [mealSearchParam, setMealSearchParam] = useState<ILibraryFields>(initMealSearchParam)
    const [mealPgCfg, setMealPgCfg] = useState<TablePaginationConfig>(initPgCfg);
    //当日菜品
    const [dayMeal, setDayMeal] = useState<IReleaseDetial>({});
    const [dayMealList, setDayMealList] = useState<number[]>([]);

    //日历时间当前月的起始时间
    const initTimeStartInEnd: ITimeStartInEnd = {
        startTime: moment().startOf('month').format("YYYY-MM-DD"),
        endTime: moment().endOf('month').endOf('month').format("YYYY-MM-DD")
    }
    const [timeStartInEnd, setTimeStartInEnd] = useState<ITimeStartInEnd>(initTimeStartInEnd)


    const loadData = async (searchValue: IReleaseFields = searchParam) => {
        let searchParams = {
            // "canteenId": 1,
            ...timeStartInEnd,
            ...searchValue,
            // "mealId": meal,
            // "startTime": "2021-05-01",
            // "endTime": "2021-07-01"
        }
        let { retData } = await releaseService.findByMeal(searchParams);
        // setPgCfg(transPgToPagination(pg));
        setDataSource(retData)

    }
    //设置搜索value
    const updateSearchParam = (updateParam: IReleaseFields) => {
        setSearchParam({ ...searchParam, ...updateParam })
    }
    //重置搜索参数
    const resetSearchParam = () => {
        setSearchParam({});
        setSelectedRowKeys([]);
        loadData({});
    }

    //菜品列表
    const loadLibrary = async (page?: ISearchPage, searchValue: ILibrarySearch = mealSearchParam) => {
        let pam: any = {}
        for (let i in searchValue) {
            if (searchValue[i]) {
                pam[i] = searchValue[i]
            }
        }
        const searchParams = {
            ...pam,
            page: {
                pageSize: 1000,
                pageNum: 1
                // pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
                // pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
            }
        }
        let { retData } = await libraryService.page(searchParams);
        let { data, ...pg } = retData;
        let newData = data.map(item => {
            return {
                ...item,
                enable: 0
            }
        })
        setMealList(newData)
    }
    //设置搜索value
    const updateLibrarySearchParam = (updateParam: ILibrarySearch) => {
        setMealSearchParam({ ...mealSearchParam, ...updateParam })
    }
    //重置搜索参数
    const resetLibrarySearchParam = () => {
        setMealSearchParam({ category: mealSearchParam.category });
        loadLibrary(initPgCfg, { category: mealSearchParam.category });
    }
    //新增菜品发布
    const insertMenu = useLockFn(async () => {
        let { canteenId, mealId } = homeInfo as IHomeInfo
        let { time } = dayMeal
        if (!!!time) {
            return globalPrompt("message", { text: "请选中发布时间", type: "error" })
        }
        let params = {
            canteenId, mealId,
            time: time as string,// ?? moment().format("YYYY-MM-DD"),
            foods: dayMealList
        }
        // return console.log(params, dayMeal)
        let { retData } = await releaseService.insert(params);
        if (retData) {
            setDayMeal({})
            globalPrompt("message", { text: "发布成功", type: "success" })
            loadData({ ...homeInfo })
        }

        // retData && loadData({ ...homeInfo })
    })
    //菜品修改
    const updateMenu = useLockFn(async () => {
        let { time } = dayMeal
        if (!!!time) {
            return globalPrompt("message", { text: "请选中发布时间", type: "success" })

        }
        let params = {
            id: dayMeal.id as number,
            foods: dayMealList
        }
        let { retData } = await releaseService.update(params);
        if (retData) {
            setDayMeal({})
            globalPrompt("message", { text: "修改成功", type: "success" })
            loadData({ ...homeInfo })
        }
        // retData && loadData({ ...homeInfo })
    })
    const [lookListData,setLookListData] = useState<any[]>([]);
    const [visibleModel,setVisibleModel] = useState<boolean>(false);
    return {
        dataSource,
        searchParam,
        initPgCfg,
        pgCfg,
        selectedRowKeys,
        visibleDrawer,
        setDataSource,
        setSearchParam,
        setPgCfg,
        loadData,
        updateSearchParam,
        resetSearchParam,
        setSelectedRowKeys,
        setVisibleDrawer,
        mealType, setMealType,
        loadLibrary,
        mealList, setMealList,
        mealSearchParam, setMealSearchParam,
        mealPgCfg, setMealPgCfg,
        dayMeal, setDayMeal,
        dayMealList, setDayMealList,
        insertMenu,
        updateMenu,
        homeInfo, setHomeInfo,
        meal, setMeal,
        mealIdList, setMealIdList,
        timeStartInEnd, setTimeStartInEnd,
        updateLibrarySearchParam,
        resetLibrarySearchParam,
        lookListData,setLookListData,
        visibleModel,setVisibleModel
    }
})