import React from "react";
import { DataTable } from "./DataTable";
import { Button, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useMealModel } from "../mealModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { importPropsConfig } from "@/utils/importFile";
import { AuthButton } from "@/components/auth/AuthButton";
import useGlobalModel from "@/model/globalModel";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
export const DataBox = () => {
    const { visibleDrawer, setVisibleDrawer, loadData, setIVisibleDrawer } = useMealModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel();
    const onAdd = () => {
        setIVisibleDrawer("add")
    }
    const title = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onAdd}
        >新增</Button>
    </>
    return (
        <YhBox title={title}>
            <DataTable />
        </YhBox>
    )
}