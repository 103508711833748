import { httpRequest } from "../../utils/HttpRequest";
import { IUserFields, IUserDetial, IUserSearch } from "./userType"
import { IMealPage } from "@/types/AppTypes";
class UserService {
    async page(searchVo: IUserSearch) {
        const apiUrl = "/common/user/page";
        return await httpRequest.post<IMealPage<IUserDetial>>(apiUrl, searchVo);
    }
    async update(searchVo: IUserFields) {
        const apiUrl = "/common/user/update";
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
}
export const userService = new UserService();
