import React, { useEffect } from "react";
import { Table } from "antd";
import { ColumnsType } from "antd/es/table";
import { useMealDetailModel } from "../mealDetailModel";
import { IMealDetail } from "../mealDetailType";
export function DataTable() {
    const {
        dataSource, selectedRowKeys, pgCfg,
        loadData, setSelectedRowKeys
    } = useMealDetailModel();

    const columns: ColumnsType<IMealDetail> = [
        {
            title: '序号',
            width: 80,
            render: (text, record, index) => `${index + 1}`,
            fixed: "left"
        }, {
            title: '部门名称',
            width: 130,
            dataIndex: 'orgName',
        }, {
            title: '人员名称',
            width: 160,
            dataIndex: 'userName',
        }, {
            title: '用户类型',
            width: 100,
            dataIndex: 'userType',
            render: text => text === "inside" ? "内部员工" : "外部客户"
        }, {
            title: '预约次数',
            width: 100,
            dataIndex: 'total',
        }, {
            title: '就餐次数',
            width: 100,
            dataIndex: 'finished',
        }, {
            title: '取消就餐次数',
            width: 100,
            dataIndex: 'canceled',
        }, {
            title: '失约次数',
            width: 100,
            dataIndex: 'overdue',
        }, {
            title: '餐厅名称',
            width: 160,
            dataIndex: 'canteenName',
        }
    ];
    useEffect(() => {
        loadData()
    }, [])

    //点击行选高亮
    const onRow = (id: string) => {
        if (selectedRowKeys.length > 0 && selectedRowKeys[0] === id) {
            setSelectedRowKeys([]);
        } else {
            setSelectedRowKeys([id as string]);
        }
    };

    return (
        <Table
            rowKey={record => record.id as string}
            // rowSelection={{
            //     type: "radio",
            //     selectedRowKeys: selectedRowKeys, //记录选中
            //     onChange: (selectedRowKeys: React.Key[], selectedRows: IMealReportDetial[]) => {
            //         setSelectedRowKeys(selectedRowKeys as string[]);
            //     }
            // }}
            columns={columns}
            dataSource={dataSource}
            pagination={{
                ...pgCfg,
                position: ["bottomCenter"],
                showTotal: () => `共${pgCfg.total}条`,
                onChange: (pageNum, pageSize) => {
                    loadData({ pageNum, pageSize })
                }
            }}
            onRow={record => ({
                onClick: () => onRow(record.id as string)
            })}
            scroll={{ x: 'max-content', y: 500 }}
        />
    )
}
