import React from "react";
import { PageHeader } from "./PageHeader";
import { DataBox } from "./DataBox";
import { BreakingDetailModel } from "./DetailModel"
export const statisticsBreaking = () => {
    return (
        <>
            <PageHeader />
            <DataBox />
            <BreakingDetailModel/>
        </>
    )
}