import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import useNavModel from "../model/navModel";
import useGlobalModel from "../model/globalModel";
import { forFind } from "../utils/utils";
import menuData from "../data/menuData.json";
import { Layout, Spin } from "antd";
import AppMenu from "./AppMenu";
import AppHeader from "./AppHeader";
import AppNav from "./AppNav";
import AppRoute from "./AppRoute";
import { loginServiceAuth2 } from "@/views/login/LoginServiceAuth2";
import { CLIENT_APP_CODE } from "@/utils/constants";
import { useDictModel } from "@/views/dict/dictModel";

const { Sider, Content } = Layout;

const Index = () => {

    const history = useHistory();
    const { user, cm, loadingInfo: { loadingVisible, txt }, setLoadingInfo, loadRestaurantAll } = useGlobalModel();
    const { loadData: loadDictAll } = useDictModel()
    const { addNav } = useNavModel();
    useEffect(() => {
        loadRestaurantAll()
        loadDictAll()
    }, [])
    //监听在地址栏输入url。
    useEffect(() => {
        history.listen((location, action) => {
            //每次切换页面 关闭全局loading
            setLoadingInfo({ loadingVisible: false, txt: "" });

            if (action === "POP" && location.pathname !== "/login" && user.token) {
                let findNav = forFind(menuData, location.pathname, "path", true);
                // console.log(location, action, "监听路有变化", findNav)
                addNav(findNav ? findNav.id : "404");
            }
        });

        //监听浏览器关闭事件(绝对好使，需所有代码放到生命周期mounted中)
        // var userAgent = navigator.userAgent; //取得浏览器的userAgent字符串  
        // var isOpera = userAgent.indexOf("Opera") > -1; //判断是否Opera浏览器  
        // var isIE = userAgent.indexOf("compatible") > -1 && userAgent.indexOf("MSIE") > -1 && !isOpera; //判断是否IE浏览器
        // var isIE11 = userAgent.indexOf("rv:11.0") > -1; //判断是否是IE11浏览器
        // var isEdge = userAgent.indexOf("Edge") > -1 && !isIE; //判断是否IE的Edge浏览器
        // if (!isIE && !isEdge && !isIE11) {//兼容chrome和firefox
        //     var _beforeUnload_time = 0, _gap_time = 0;
        //     var is_fireFox = navigator.userAgent.indexOf("Firefox") > -1;//是否是火狐浏览器
        //     window.onunload = function () {
        //         _gap_time = new Date().getTime() - _beforeUnload_time;
        //         if (_gap_time <= 5) {
        //             // window.localStorage.setItem('uqqqqqqqqqqqqqqq', "谷歌浏览器关闭");//谷歌浏览器关闭
        //             loginServiceAuth2.Logout();
        //             // loginServiceAuth2.recordAppLogoutInfo(CLIENT_APP_CODE, user.userCode ?? "");
        //         } else {//谷歌浏览器刷新
        //         }
        //     }
        //     window.onbeforeunload = function () {
        //         _beforeUnload_time = new Date().getTime();
        //         if (is_fireFox) {//火狐关闭执行
        //             loginServiceAuth2.Logout();
        //             // loginServiceAuth2.recordAppLogoutInfo(CLIENT_APP_CODE, user.userCode ?? "");
        //             // window.localStorage.setItem('uqqqqqqqqqqqqqqq', "88888888888");;//火狐浏览器关闭
        //         } else {//火狐浏览器刷新
        //         }
        //     };
        // };
        // return () => {
        //     console.log("2222222222")
        //     //组件销毁直接刷新下页面，  初始所有缓存参数
        //     window.location.replace(`${window.origin}`)
        // }
    }, []);

    return (
        <Layout style={{ height: "100vh" }}>
            <AppHeader />
            <Layout>
                <Sider className="layout-sider" trigger={null} collapsible collapsed={cm.collapsed} width={226} collapsedWidth={80}>
                    <div className="layout-sider-shop">{user.shopName}</div>
                    <AppMenu />
                </Sider>
                <Layout className="layout-section">
                    <AppNav />
                    <Spin tip={txt} spinning={loadingVisible}>
                        <Content>
                            <div className="layout-section-content">
                                <AppRoute />
                            </div>
                        </Content>
                    </Spin>
                </Layout>
            </Layout>
        </Layout>
    )
}

export default Index;