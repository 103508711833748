import { httpRequest } from "@/utils/HttpRequest";
import {IReleaseDetial,IReleaseSearch,IReleaseFields,IReleaseInsert,IReleaseUpdate} from "./releaseTypes"

class ReleaseService {
    async findByMeal(searchVo: IReleaseFields) {
        const apiUrl = "/common/menu/findByMeal";
        return await httpRequest.post<IReleaseDetial[]>(apiUrl, searchVo);
    }
    async insert(searchVo: IReleaseInsert) {
        const apiUrl = "/common/menu/insert";
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
    async update(searchVo: IReleaseUpdate) {
        const apiUrl = "/common/menu/update";
        return await httpRequest.post<boolean>(apiUrl, searchVo);
    }
}
export const releaseService = new ReleaseService();
