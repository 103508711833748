import React from "react";
import { useMealDetailModel } from "../mealDetailModel";
import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { useDictModel } from "@/views/dict/dictModel";
import { DictCodeEnmu } from "@/types/AppDictEnums";

export function PageHeader () {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useMealDetailModel();
    const { dataRestaurantSource } = useGlobalModel()

    const { getSelectedDictOne } = useDictModel()

    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "部门名称",
            fieldName: "orgCode",
            options: getSelectedDictOne(DictCodeEnmu.SUOSHUJIGOU)
        },
        {
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "餐厅名称",
            fieldName: "canteenId",
            options: transOptions(dataRestaurantSource)
        },
        {
            type: SearchTypeEnum.Input,
            span: 4,
            placeholder: "人员名称",
            fieldName: "userName",
        },{
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "用户类型",
            fieldName: "userType",
            options:[
                {
                    label:'内部员工',
                    value:'inside'
                },
                {
                    label:'外部客户',
                    value:'outside'
                }
            ]
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
    ]
    return (
        <YhPageHeader
            title="报餐详情"
            triggerSearch={true}
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    );
}