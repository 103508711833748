import React from "react";
import { Button, Upload, Input } from "antd";
import { YhBox } from "@/components/YhBox";
import { useReleaseModel } from "../releaseModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { importPropsConfig } from "@/utils/importFile";
import { AuthButton } from "@/components/auth/AuthButton";
import useGlobalModel from "@/model/globalModel";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import { IMealTypeEnum, IMealTypeObj } from "@/types/AppEnums";
import { IMealFields } from "@/views/meal/mealTypes";
import { YhPageHeader } from "@/components/YhPageHeader";
import { SearchTypeEnum, ISearchValue } from "@/components/YhPageHeader/Types";
export const TabBox = () => {
    const { visibleDrawer, setVisibleDrawer, loadData,
        mealSearchParam,
        meal,
        mealIdList,
        homeInfo,
        initPgCfg,
        setHomeInfo,
        setMealSearchParam,
        updateLibrarySearchParam,
        resetLibrarySearchParam,
        loadLibrary,
        setMeal } = useReleaseModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel();

    const onTab = (meal: IMealFields) => {
        let { id: mealId, mealType } = meal as { id: string, mealType: IMealTypeEnum }
        setHomeInfo({ canteenId: homeInfo?.canteenId, mealId })
        setMeal(mealType)
        loadData({ canteenId: homeInfo?.canteenId, mealId })
    }
    const title = <>

        {mealIdList.map(item => (
            <Button
                key={item.id}
                className={`mr-5 ${meal === item.mealType ? 'tab-buts-active' : ''}`}
                type="link"
                shape="round"
                onClick={() => onTab(item)}
            >{IMealTypeObj[item.mealType as IMealTypeEnum]}</Button>
        ))}
    </>
    const rightChildren = <div className="flex">
        <Input
            allowClear
            datatype="round"
            className="border-r-16 mr-10"
            value={mealSearchParam.name}
            style={{ width: "150px" }}
            onChange={(e) => { setMealSearchParam({ ...mealSearchParam, name: e.target.value }) }}
            onPressEnter={() => loadLibrary(initPgCfg,{})}
            placeholder="菜品名称"
        />
        <Button onClick={() => loadLibrary()} style={{ margin: "0px 12px 0px 15px" }} shape="round">检索</Button>
        <Button onClick={() => resetLibrarySearchParam()} shape="round">重置</Button>
    </div>
    return (
        <YhBox title={title} rightChildren={rightChildren}>
        </YhBox>
    )
}