import React from "react";
import { DataTable } from "./DataTable";
import { Button, Upload } from "antd";
import { YhBox } from "@/components/YhBox";
import { useLibraryModel } from "../libraryModel";
import { importPropsConfig } from "@/utils/importFile";
export const DataBox = () => {
    const { visibleDrawer, setIVisibleDrawer, listExport, setMd5FormVal, loadData } = useLibraryModel();

    const onAdd = () => {
        setIVisibleDrawer("add")
        setMd5FormVal([])
    }
    const onImport = () => {

    }
    const onExport = () => {
        listExport()
    }
    const title = <>
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onAdd}
        >新增</Button>
        <Upload showUploadList={false} className="mr-10 ml-10" {...importPropsConfig(`/common/food/import`, loadData)}>
            <Button className="mr-5" type="link" shape="round">导入</Button>
        </Upload>
        {/* <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onImport}
        >导入</Button> */}
        <Button
            className="mr-5"
            type="link"
            shape="round"
            onClick={onExport}
        >导出</Button>
    </>
    return (
        <YhBox title={title}>
            <DataTable />
        </YhBox>
    )
}