import React from "react";
import { Layout, Avatar, Dropdown, Menu, Select, message } from "antd";
import FontIcon from "../components/YhIcon";
import { useHistory } from "react-router-dom";
import useGlobalModel from "../model/globalModel";
import useNavModel from "../model/navModel";
import Logo from "@/assets/images/header-logo.png"
import { ISelectOption } from "@/components/YhFormBox/Types";
import { isArray } from "util";
import { UserOutlined } from "@ant-design/icons";
import { loginServiceAuth2 } from "@/views/login/LoginServiceAuth2";
import { closeWebPage } from "@/utils/utils";
import { CLIENT_APP_CODE } from "@/utils/constants";

const { Header } = Layout;

function AppHeader() {
    const history = useHistory();
    const { user, setUser, } = useGlobalModel();
    const { initNav } = useNavModel();

    //退出登录
    const onLogout = () => {
        if (user.loginType === "account") {
            setUser({ ...user, shopId: "", shopName: "", token: "" });
            initNav();
            history.push("/login");
            loginServiceAuth2.Logout();
            loginServiceAuth2.recordAppLogoutInfo(CLIENT_APP_CODE, user.userCode ?? "");
        } else {
            closeWebPage();
        }

    };

    const onClearCache = () => {
        initNav()
    }

    // 菜单项
    const HeaderMenu = () => {
        return (
            <Menu>
                <Menu.Item key="0">
                    <span onClick={onClearCache}>关闭所有标签</span>
                </Menu.Item>
                {/* <Menu.Divider /> */}
            </Menu>
        )
    }



    return (
        <Header className="layout-section-header">
            {/* 左侧按钮 */}
            <div className="layout-sider-logo">
                <img className="layout-sider-logo-img" src={Logo} alt="管理系统Logo" />
                <span className="one-line">报餐系统</span>
            </div>

            {/* <span style={{ fontSize: "22px", fontWeight: "bold", color: "#2db7f5" }}>{user.shopName}</span> */}
            {/* 右侧内容 */}
            <div className="header-right" style={{ paddingRight: "15px", width: "500px" }}>
                {/* 设置 */}
                <Dropdown overlay={HeaderMenu} placement="bottomCenter" trigger={['click']}>
                    <Avatar className="header-right-item config-icon" icon={<FontIcon type="icon-shezhi" />} />
                </Dropdown>

                {/* 登录用户信息 */}
                <span onClick={e => e.preventDefault()} className="header-right-item user-info white-space">
                    <Avatar
                        size="default"
                        icon={<UserOutlined />}
                        className="user-icon"
                    />
                    <span className="account">{user.userName ?? "未获取到"}</span>
                </span>

                {/* 退出登录 */}
                <FontIcon type="icon-tuichu" className="header-right-item logout-icon" onClick={() => onLogout()} />
            </div>
        </Header>
    )
}

export default AppHeader;