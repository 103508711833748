import { createModel } from 'hox';
import { useState, useEffect } from 'react';
import {
	IMealDetailSearch,
	IMealDetail,
	IMealDetailFields,
} from './mealDetailType';
import { TablePaginationConfig } from 'antd/es/table/interface';
import { DEFAULT_FIRST_PAGE_NUM, DEFAULT_PAGE_SIZE } from '@/utils/constants';
import { mealDetailsService } from './mealDetailService';
import { ISearchPage } from '@/types/AppTypes';
import { transPgToPagination } from '@/model/globalModel';
import { useLockFn } from 'ahooks';
import { globalPrompt } from '@/components/message';
import { convertRes2Blob } from '@/utils/exportFile';

export const useMealDetailModel = createModel(function () {
	//列表数据源
	const [dataSource, setDataSource] = useState<IMealDetail[]>([]);
	//查询条件
	const [searchParam, setSearchParam] = useState<IMealDetailFields>({});
	//抽屉框
	const [visibleDrawer, setVisibleDrawer] = useState<boolean>(false);
	const initDesc = {
		orgTotal: 0, // 33,
		bookingTotal: 0, // 3361,
		finishedTotal: 0, // 2697,
		canceledTotal: 0, // 200,
		overdueTotal: 0, // 464,
		overdueRate: 0, // "13.81",
	};
	const initPgCfg: TablePaginationConfig = {
		pageSize: DEFAULT_PAGE_SIZE,
		current: DEFAULT_FIRST_PAGE_NUM,
	};
	const [pgCfg, setPgCfg] = useState<TablePaginationConfig>(initPgCfg);
	// const [descTotal, setDescTotal] = useState<IMealReportStatistics>(initDesc);

	//表格多选id值
	const [selectedRowKeys, setSelectedRowKeys] = useState<string[]>([]);
	const loadData = async (page?: ISearchPage, searchValue: IMealDetailFields = searchParam) => {
		const searchParams = {
			...searchValue,
			userId: selectedRowKeys[0],
			page: {
				pageSize: page?.pageSize ?? pgCfg.pageSize ?? DEFAULT_PAGE_SIZE,
				pageNum: (page?.pageNum || page?.current) ?? pgCfg.current ?? DEFAULT_FIRST_PAGE_NUM,
			},
		};
		let { retData } = await mealDetailsService.page(searchParams);
		let { data, ...pg } = retData;
		setPgCfg(transPgToPagination(pg));
		setDataSource(data);
	};
	//设置搜索value
	const updateSearchParam = (updateParam: IMealDetailFields) => {
		setSearchParam({ ...searchParam, ...updateParam });
	};
	//重置搜索参数
	const resetSearchParam = () => {
		setSearchParam({});
		setSelectedRowKeys([]);
		loadData(initPgCfg, {});
	};
	// 列表导出
	const listExport = async () => {
		let result = await mealDetailsService.export({ userId: selectedRowKeys[0] });
		convertRes2Blob(result);
	};

	return {
		dataSource,
		searchParam,
		initPgCfg,
		pgCfg,
		selectedRowKeys,
		visibleDrawer,
		setDataSource,
		setSearchParam,
		setPgCfg,
		loadData,
		updateSearchParam,
		resetSearchParam,
		setSelectedRowKeys,
		setVisibleDrawer,
		listExport,
		// descTotal,
		// setDescTotal,
	};
});
