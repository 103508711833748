import React from "react";
import { useMealReportModel } from "../mealReportModel";


import { ISearchValue, SearchTypeEnum } from "@/components/YhPageHeader/Types";
import { YhPageHeader } from "@/components/YhPageHeader";
import useGlobalModel, { transOptions } from "@/model/globalModel";
import { useDictModel } from "@/views/dict/dictModel";
import { DictCodeEnmu } from "@/types/AppDictEnums";

export function PageHeader() {
    const {
        searchParam,
        updateSearchParam,
        resetSearchParam,
        loadData
    } = useMealReportModel();
    const { dataRestaurantSource } = useGlobalModel()

    const { getSelectedDictOne } = useDictModel()

    const searchFields = [
        {
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "部门名称",
            fieldName: "orgCode",
            options: getSelectedDictOne(DictCodeEnmu.SUOSHUJIGOU)
        },
        {
            type: SearchTypeEnum.Select,
            span: 4,
            placeholder: "餐厅名称",
            fieldName: "canteenId",
            options: transOptions(dataRestaurantSource)
        },
        {
            type: SearchTypeEnum.RangePicker,
            fieldName: "",
            placeholder: ["制单开始", "制单结束"],
            rangePickerFieldName: ["startTime", "endTime"],
            format: "YYYY-MM-DD",
            span: 12,
            xxl: 6,
        },
    ]
    return (
        <YhPageHeader
            title="报餐记录"
            triggerSearch={true}
            justify="end"
            searchValues={searchParam as ISearchValue}
            searchFields={searchFields}
            onChange={updateSearchParam}
            handleSearch={loadData}
            handleReset={resetSearchParam}
        />
    );
}