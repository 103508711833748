import React, { FC } from "react";
import { IFormItem } from "../Types";
import { Form, Input } from "antd";

export const __FormInput: FC<IFormItem> = ({ placeholder, fieldLabel, fieldName, ...props }) => {
    return (
        <Form.Item
            label={fieldLabel}
            name={fieldName}
            rules={props.rules}
            hidden={props.hidden}
            labelCol={props.labelCol}
        >
            <Input
                disabled={props.disable}
                allowClear={props.allowClear}
                placeholder={placeholder ? placeholder : typeof fieldLabel === "string" ? fieldLabel : ""}
            />
        </Form.Item>
    )
}