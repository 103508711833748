import React from 'react';
import ReactDOM from 'react-dom';
import {ConfigProvider} from "antd";
import App from './App';
import reportWebVitals from './reportWebVitals';
import { HashRouter } from "react-router-dom";
import './index.less';
import 'moment/locale/zh-cn';
import locale from 'antd/lib/locale/zh_CN';
import es_ES from 'antd/lib/locale/es_ES';
import moment from 'moment';

ReactDOM.render(
    <ConfigProvider locale={locale}>
        <HashRouter>
            <App />
        </HashRouter>
    </ConfigProvider>,
    document.getElementById('root')
);

reportWebVitals();
