import React, { useEffect } from "react";
import { Button, Upload, Calendar, Modal } from "antd";
import { YhBox } from "@/components/YhBox";
import { useReleaseModel } from "../releaseModel";
import useNavModel from "@/model/navModel";
import { globalPrompt } from "@/components/message";
import { importPropsConfig } from "@/utils/importFile";
import { AuthButton } from "@/components/auth/AuthButton";
import useGlobalModel from "@/model/globalModel";
import { DescriptionColorEnum, IDescription } from "@/components/YhBox/Types";
import moment from "moment"
import YhTag from "@/components/YhTag";
import { ToolOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { IRestaurantDetial } from "@/views/restaurant/restaurantTypes";
import { mealService } from "@/views/meal/mealService";
import { IMealFields } from "@/views/meal/mealTypes";
import { FoodCategory, IMealTypeEnum, IMealTypeObj } from "@/types/AppEnums";
type statusType = {
    "cold": string,
    "hot": string,
    "staple": string,
    "other": string,
    [key: string]: any
}
const statusColor: statusType = {
    "cold": "green",
    "hot": "red",
    "staple": "cyan",
    "other": "lime",

}
export const CalendarBox = () => {
    const { visibleDrawer, dataSource, dayMeal, dayMealList, homeInfo,
        setVisibleDrawer, loadData, setDayMeal, setDayMealList,
        setHomeInfo,
        setMealType,
        setMeal,
        mealIdList, setMealIdList,
        timeStartInEnd, setTimeStartInEnd,
        setLookListData,
        setVisibleModel
    } = useReleaseModel();
    const { addNav, findNav, selectNav, switchNavState } = useNavModel();
    const { dataRestaurantSource, restaurantSourceOne } = useGlobalModel()
    useEffect(() => {
        // let setCanteenModal = Modal.confirm({
        //     title: <span style={{ fontSize: "20px", fontWeight: "bold" }}>请选择餐厅</span>,
        //     centered: true,
        //     closable: false,
        //     icon: <ToolOutlined />,
        //     className: "login-shop-modal",
        //     content: <div className="login-shop-container">
        //         {
        //             dataRestaurantSource.length > 0 ? dataRestaurantSource.map(item => (
        //                 <span key={item.id} onClick={() => setConteen(item, setCanteenModal)}>{item.name}</span>
        //             )) : <span onClick={() => { addNav("2"); setCanteenModal.destroy(); }} style={{ background: "#FD8700" }}>去增加餐别</span>
        //         }
        //     </div>,
        // });
        setConteen(restaurantSourceOne)
        return () => {
            setHomeInfo(null);
            // setCanteenModal.destroy();
        }
    }, []);
    const setConteen = async (item: IRestaurantDetial, canteenModalInst?: any) => {
        console.log(item)
        let { id: canteenId } = item as { id: string }
        let { retData: { data: mealData } } = await mealService.page({
            canteenId,
            "page": {
                "pageSize": 10,
                "pageNum": 1
            }
        });
        setMealIdList(mealData)
        // canteenModalInst?.destroy();
        let setMealModal = Modal.confirm({
            title: <span style={{ fontSize: "20px", fontWeight: "bold" }}>请选择餐别</span>,
            centered: true,
            closable: false,
            icon: <ToolOutlined />,
            className: "login-shop-modal",
            content: <div className="login-shop-container">
                {
                    mealData && mealData.length > 0 ? mealData.map(item => (
                        <span key={item.id} onClick={() => setFoodMenu(item, canteenId, setMealModal)}>{IMealTypeObj[item.mealType as IMealTypeEnum]}</span>
                    )) : <span onClick={() => { addNav("3"); setMealModal.destroy(); }} style={{ background: "#FD8700" }}>去增加餐别</span>
                }
            </div>,
            // onCancel: () => { loginServiceAuth2.Logout(); setLoading(false); }
        });
        // setHomeInfo({ canteenId });
        // loadData({ canteenId })
        // canteenModalInst?.destroy();

    }
    //选中餐别 餐厅id 请求到 当前的菜单发布页
    const setFoodMenu = (item: IMealFields, canteenId: string, mealModalInst: any) => {
        let mealId = item.id as string
        setMeal(item.mealType as IMealTypeEnum)
        setHomeInfo({ canteenId, mealId });
        loadData({ canteenId, mealId })
        mealModalInst?.destroy();
    }
    const onLook = (listData: any) => {
        console.log(listData)
        setVisibleModel(true);
        setLookListData(listData)
    }
    const getListData = (value: any) => {
        let listData: any[] = []
        console.log(dataSource)
        dataSource.forEach((item) => {
            if (moment(item.time).format("YYYY-MM-DD") === value.format("YYYY-MM-DD")) {
                item.foods && item.foods?.map((food) => {
                    let { category, name, id, image, labelName, orderNum } = food
                    listData.push({ category, name, id, image, labelName, orderNum })
                })
            }
        })
        return listData ?? []
    }
    const dateCellRender = (value: any) => {
        const listData = getListData(value)
        // console.log(listData)
        // return <></>
        return <>
            {listData.length !== 0 && <PlusCircleOutlined onClick={(e) => { e.stopPropagation(); onLook(listData) }} style={{ fontSize: '16px', }} />}
            {listData.map(item => (
                <YhTag key={item.name} color={statusColor[item.category]} text={item.name} />
            ))}
        </>
    }
    const onDayChange = (date: any) => {
        let newDataSource = dataSource.filter((item) => {
            // console.log(moment(item.time).format("YYYY-MM-DD"), date.format("YYYY-MM-DD")) color: '#52c41a',marginRight:"15px"
            if (moment(item.time).format("YYYY-MM-DD") === date.format("YYYY-MM-DD")) {
                return item
            }
        })[0]
        // console.log(date.format("YYYY-MM-DD"), newDataSource)

        setDayMeal({ ...newDataSource, time: date.format("YYYY-MM-DD") })
        setDayMealList(newDataSource?.foods?.map(item => item.id as number) ?? [])
        // console.log(newDataSource,date.date(),dataSource)
    }
    const onPanelChange = (date: any, mode: string) => {
        setTimeStartInEnd(
            {
                startTime: moment(date).startOf('month').format("YYYY-MM-DD"),
                endTime: moment(date).endOf('month').endOf('month').format("YYYY-MM-DD")
            }
        )
        loadData({
            ...homeInfo,
            startTime: moment(date).startOf('month').format("YYYY-MM-DD"),
            endTime: moment(date).endOf('month').endOf('month').format("YYYY-MM-DD"),
        })
    }
    return (
        <YhBox
            title={""}
            style={{
                // width: "783px",
                width: "calc(100vw - 321px)",
                height: "690px",
                marginRight: "15px",
                overflowY: "auto"
            }}
        >
            <Calendar
                dateCellRender={dateCellRender}
                onSelect={onDayChange}
                onPanelChange={onPanelChange}
            // onSelect={() => { console.log('onSelect') }}
            />
        </YhBox>
    )
}